.thankyou-main {
    box-sizing: border-box;
  }
  
  .thankyou-section-1 {
    overflow-y: hidden;
    padding-bottom: 50px;
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    text-align: center;
    .sect-1-container {
      max-width: fit-content;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .txt-1 {
        .white-txt {
          padding: 0;
          margin-bottom: 0;
          overflow-y: hidden;
          color: $white-text;
          font-size: $largeHeading-vw - 0.5;
          font-weight: $bold;
          word-spacing: -20px;
  
          .txt-1-yellow-txt{
            color: $yellow !important;
          }
        }
      }
      .txt-2 {
        .yellow-txt {
          margin-bottom: 0;
          overflow-y: hidden;
          color: $yellow;
          font-size: $largeHeading-vw - 0.5;
          font-weight: $bold;
        }
      }
  
      // to add fade up effect to the hollow text
  
      @keyframes zoomIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
  
      .txt-3 {
        .hollow-txt {
          margin-bottom: 0;
          overflow-y: auto;
          color: transparent;
          font-size: $largeHeading-vw - 0.5;
          font-weight: $bold;
          word-spacing: -17px;
          -webkit-text-stroke: 2px $white-text;
          -webkit-background-clip: text; //to send text to the background
          background-image: radial-gradient(
            closest-side,
            $yellow 100%,
            transparent 0
          ); //for the yellow ball in hollow text
          background-repeat: no-repeat;
          background-size: 400px 400px;
          animation: zoomIn 2s ease-in-out;
        }
      }
  
      // to add fade down effect to the thankyou-phrase text
  
      @keyframes fadeDown {
        from {
          opacity: 0;
          transform: translateY(-100%);
        }
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
  
      .thankyou-phrase {
        overflow-y: hidden;
        padding-top: 60px;
        padding-bottom: 5px;
        text-align: center;
        animation: fadeDown 1.5s ease-in-out;
        .thankyou-text {
          color: $white-text;
          font-size: $smallFont + 2;
          font-weight: $medium;
          margin-bottom: 5px;
        }
        .i {
          font-size: $xsmallHeading;
          font-weight: $thin;
          color: $white-text;
          transition: all 0.5s ease-in-out;
        }
        .i:hover {
          color: $yellow;
          transform: translateY(10px);
        }
      }
    }
  }
  
  @media (max-width: 1024px) {
    .thankyou-section-1 {
      .sect-1-container {
        max-width: fit-content;
        .txt-1 {
          .white-txt {
            font-size: $largeHeading-vw;
          }
        }
        .txt-2 {
          .yellow-txt {
            font-size: $largeHeading-vw;
          }
        }
        .txt-3 {
          .hollow-txt {
            font-size: $largeHeading-vw - 0.2;
          }
        }
      }
      .thankyou-phrase {
        padding-top: 40px !important;
      }
    }
  }
  
  // to disable hollow text animation for screen smaller than 992px
  @media (max-width: 991px) {
    .thankyou-section-1 {
      .txt-3 {
        .hollow-txt {
          background-image: radial-gradient(
            closest-side,
            transparent 100%,
            transparent 0
          ) !important;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .thankyou-section-1 {
      .sect-1-container {
        .txt-1 {
          .white-txt {
            font-size: $largeHeading-vw + 2;
            word-spacing: 0;
          }
        }
        .txt-2 {
          .yellow-txt {
            font-size: $largeHeading-vw + 2;
          }
        }
        .txt-3 {
          .hollow-txt {
            font-size: $largeHeading-vw + 2;
            word-spacing: 0;
            color: $white-text !important;
            -webkit-text-stroke: 0px $white-text !important;
            opacity: 1 !important;
          }
        }
        .thankyou-phrase {
          .thankyou-text {
            font-size: $smallFont;
          }
          .i {
            font-size: $xsmallHeading - 2;
          }
        }
      }
    }
  }
  
  @media (max-width: 575px) {
    .thankyou-section-1 {
      .sect-1-container {
        .txt-3 {
          .hollow-txt {
            font-size: $largeHeading-vw + 2.5;
            letter-spacing: 2px;
            -webkit-text-stroke: 1px $white-text;
          }
        }
      }
    }
  }
  
  @media (max-width: 425px) {
    .thankyou-section-1 {
      .sect-1-container {
        .txt-1 {
          .white-txt {
            font-size: $largeHeading-vw + 2.5;
          }
        }
        .txt-2 {
          .yellow-txt {
            font-size: $largeHeading-vw + 2.5;
          }
        }
        .txt-3 {
          .hollow-txt {
            font-size: $largeHeading-vw + 2.5;
          }
        }
        .thankyou-phrase {
          .thankyou-text {
            font-size: $smallFont - 2;
          }
          .i {
            font-size: $xsmallHeading - 4;
          }
        }
      }
    }
  }
  
  @media (max-width: 375px) {
    .thankyou-section-1 {
      .sect-1-container {
        .txt-1 {
          .white-txt {
            font-size: $largeHeading-vw + 3;
          }
        }
        .txt-2 {
          .yellow-txt {
            font-size: $largeHeading-vw + 3;
          }
        }
        .txt-3 {
          .hollow-txt {
            font-size: $largeHeading-vw + 3;
          }
        }
      }
    }
  }
  
  @media (max-width: 320px) {
    .thankyou-section-1 {
      .sect-1-container {
        .txt-1 {
          .white-txt {
            font-size: $largeHeading-vw + 3;
          }
        }
        .txt-2 {
          .yellow-txt {
            font-size: $largeHeading-vw + 3;
          }
        }
        .txt-3 {
          .hollow-txt {
            font-size: $largeHeading-vw + 3;
          }
        }
      }
    }
  }
  
  // section1 styling ends here