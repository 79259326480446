.footer {
  background-color: $black-grey;
  padding: 5% 0 5% 0;
  .footer-section-1 {
    .footer-sect-1-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2%;
      .footer-logo-img {
        text-align: left;
        height: auto;
        width: 150px;
      }
      .social-list {
        text-align: right;
        i {
          font-size: $xsmallHeading;
          padding-right: 15px;
          color: $yellow;
          transition: all 0.5s ease-in-out;
        }
        i:hover {
          font-size: $xsmallHeading;
          padding-right: 15px;
          color: $white-text;
        }
      }
    }
  }
  .footer-section-2 {
    .footer-line:not([size]) {
      height: 1px;
    }

    .footer-line {
      background: $light-grey;
      opacity: 1;
      margin: 0;
    }
  }
  .footer-section-3 {
    padding: 3% 0% 0 1%;
    .footer-sect-3-row {
      .footer-left-links {
        .left-links {
          p {
            color: $yellow;
            font-size: $mediumHeading;
          }
          ul {
            padding: 0;
            list-style-type: none;
            text-align: start;
            li {
              a {
                text-decoration: none !important;
                color: $white-text;
                font-size: $smallFont;
                line-height: 2rem;
                transition: all 0.5s ease-in-out;
              }
              a:hover {
                color: $yellow;
              }
            }
          }
        }
      }
      .footer-middle-links {
        .middle-links {
          p {
            color: $yellow;
            font-size: $mediumHeading;
          }
          ul {
            padding: 0;
            list-style-type: none;
            text-align: start;
            li {
              a {
                text-decoration: none !important;
                color: $white-text;
                font-size: $smallFont;
                line-height: 2rem;
                transition: all 0.5s ease-in-out;
              }
              a:hover {
                color: $yellow;
              }
            }
          }
        }
      }
      .footer-right-content {
        .right-content {
          .right-content-title {
            color: $yellow;
            font-size: $mediumHeading;
          }
          .right-content-text {
            text-align: justify;
            color: $white-text;
            font-size: $smallFont;
            line-height: 2rem;
            a {
              color: $yellow;
              text-decoration: none !important;
              transition: all 0.5s ease-in-out;
            }
            a:hover {
              color: $white-text;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .footer {
    .footer-section-1 {
      .footer-sect-1-row {
        .footer-logo-img {
          height: auto; //to fix the size of logo on smaller screens
          width: 140px;
        }
      }
    }
    .footer-section-3 {
      .footer-sect-3-row {
        padding-left: 1%;
      }
    }
  }
}

@media (max-width: 991px) {
  .footer {
    .footer-section-1 {
      .footer-sect-1-row {
        .footer-logo-img {
          height: auto; //to fix the size of logo on smaller screens
          width: 140px;
        }
      }
    }
    .footer-section-3 {
      .footer-sect-3-row {
        padding-left: 1%;
      }
    }
  }
}

@media (max-width: 768px) {
  .footer {
    .footer-section-1 {
      .footer-sect-1-row {
        .footer-logo-img {
          height: auto; //to fix the size of logo on smaller screens
          width: 130px;
        }
      }
    }
    .footer-section-3 {
      .footer-sect-3-row {
        padding-left: 1%;
      }
    }
  }
}

@media (max-width: 576px) {
  .footer {
    background-color: $black-grey;
    padding: 5%;
    .footer-section-1 {
      .footer-sect-1-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2%;
        .footer-logo-img {
          text-align: left;
          height: auto;
          width: 110px;
        }
        .social-list {
          padding-top: 3%;
          text-align: right;
          i {
            font-size: $mediumFont;
            padding-right: 15px;
            color: $yellow;
            transition: all 0.5s ease-in-out;
          }
          i:hover {
            font-size: $mediumFont;
            padding-right: 15px;
            color: $white-text;
          }
        }
      }
    }
    .footer-section-2 {
      .footer-line:not([size]) {
        height: 1px;
      }

      .footer-line {
        background: $light-grey;
        opacity: 1;
        margin: 0;
      }
    }
    .footer-section-3 {
      padding: 3% 0% 0 1%;
      .footer-sect-3-row {
        display: flex;
        justify-content: space-between;
        .footer-left-links {
          .left-links {
            p {
              color: $yellow;
              font-size: $smallHeading;
            }
            ul {
              padding: 0;
              list-style-type: none;
              text-align: start;
              li {
                a {
                  text-decoration: none !important;
                  color: $white-text;
                  font-size: $xsmallFont;
                  line-height: 2rem;
                  transition: all 0.5s ease-in-out;
                }
                a:hover {
                  color: $yellow;
                }
              }
            }
          }
        }
        .footer-middle-links {
          .middle-links {
            p {
              color: $yellow;
              font-size: $smallHeading;
            }
            ul {
              padding: 0;
              list-style-type: none;
              text-align: start;
              li {
                a {
                  text-decoration: none !important;
                  color: $white-text;
                  font-size: $xsmallFont;
                  line-height: 2rem;
                  transition: all 0.5s ease-in-out;
                }
                a:hover {
                  color: $yellow;
                }
              }
            }
          }
        }
        .footer-right-content {
          .right-content {
            .right-content-title {
              color: $yellow;
              font-size: $smallHeading;
            }
            .right-content-text {
              text-align: justify;
              color: $white-text;
              font-size: $xsmallFont;
              line-height: 2rem;
              a {
                color: $yellow;
                text-decoration: none !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .footer {
    background-color: $black-grey;
    padding: 5%;
    .footer-section-1 {
      .footer-sect-1-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2%;
        .footer-logo-img {
          text-align: left;
          height: auto;
          width: 110px;
        }
        .social-list {
          text-align: right;
          i {
            font-size: $mediumFont;
            padding-right: 15px;
            color: $yellow;
            transition: all 0.5s ease-in-out;
          }
          i:hover {
            font-size: $mediumFont;
            padding-right: 15px;
            color: $white-text;
          }
        }
      }
    }
    .footer-section-2 {
      .footer-line:not([size]) {
        height: 1px;
      }

      .footer-line {
        background: $light-grey;
        opacity: 1;
        margin: 0;
      }
    }
    .footer-section-3 {
      padding: 3% 0% 0 1%;
      .footer-sect-3-row {
        display: flex;
        justify-content: space-between;
        .footer-left-links {
          .left-links {
            p {
              color: $yellow;
              font-size: $xsmallHeading;
            }
            ul {
              padding: 0;
              list-style-type: none;
              text-align: start;
              li {
                a {
                  text-decoration: none !important;
                  color: $white-text;
                  font-size: $xsmallFont;
                  line-height: 2rem;
                  transition: all 0.5s ease-in-out;
                }
                a:hover {
                  color: $yellow;
                }
              }
            }
          }
        }
        .footer-middle-links {
          .middle-links {
            p {
              color: $yellow;
              font-size: $xsmallHeading;
            }
            ul {
              padding: 0;
              list-style-type: none;
              text-align: start;
              li {
                a {
                  text-decoration: none !important;
                  color: $white-text;
                  font-size: $xsmallFont;
                  line-height: 2rem;
                  transition: all 0.5s ease-in-out;
                }
                a:hover {
                  color: $yellow;
                }
              }
            }
          }
        }
        .footer-right-content {
          .right-content {
            .right-content-title {
              color: $yellow;
              font-size: $xsmallHeading;
            }
            .right-content-text {
              text-align: justify;
              color: $white-text;
              font-size: $xsmallFont;
              line-height: 2rem;
              a {
                color: $yellow;
                text-decoration: none !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .footer {
    background-color: $black-grey;
    padding: 5%;
    .footer-section-1 {
      .footer-sect-1-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2%;
        .footer-logo-img {
          text-align: left;
          height: auto;
          width: 110px;
        }
        .social-list {
          text-align: right;
          i {
            font-size: $smallFont;
            padding-right: 15px;
            color: $yellow;
            transition: all 0.5s ease-in-out;
          }
          i:hover {
            font-size: $smallFont;
            padding-right: 15px;
            color: $white-text;
          }
        }
      }
    }
    .footer-section-3 {
      padding: 3% 0% 0 4%;
      .footer-sect-3-row {
        display: flex;
        justify-content: space-between;
        .footer-left-links {
          .left-links {
            p {
              color: $yellow;
              font-size: $smallHeading;
            }
            ul {
              padding: 0;
              list-style-type: none;
              text-align: start;
              li {
                a {
                  text-decoration: none !important;
                  color: $white-text;
                  font-size: $xsmallFont;
                  line-height: 2rem;
                  transition: all 0.5s ease-in-out;
                }
                a:hover {
                  color: $yellow;
                }
              }
            }
          }
        }
        .footer-middle-links {
          .middle-links {
            p {
              color: $yellow;
              font-size: $smallHeading;
            }
            ul {
              padding: 0;
              list-style-type: none;
              text-align: start;
              li {
                a {
                  text-decoration: none !important;
                  color: $white-text;
                  font-size: $xsmallFont;
                  line-height: 2rem;
                  transition: all 0.5s ease-in-out;
                }
                a:hover {
                  color: $yellow;
                }
              }
            }
          }
        }
        .footer-right-content {
          .right-content {
            .right-content-title {
              color: $yellow;
              font-size: $smallHeading;
            }
            .right-content-text {
              text-align: justify;
              color: $white-text;
              font-size: $xsmallFont;
              line-height: 2rem;
              a {
                color: $yellow;
                text-decoration: none !important;
                transition: all 0.5s ease-in-out !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 297px) {
  .footer {
    background-color: $black-grey;
    padding: 5%;
    .footer-section-1 {
      .footer-sect-1-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2%;
        .footer-logo-img {
          text-align: left;
          height: auto;
          width: 110px;
        }
        .social-list {
          text-align: right;
          i {
            font-size: $xsmallFont;
            padding-right: 15px;
            color: $yellow;
            transition: all 0.5s ease-in-out;
          }
          i:hover {
            font-size: $xsmallFont;
            padding-right: 15px;
            color: $white-text;
          }
        }
      }
    }
  }
}
