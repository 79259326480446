.panel {
    transition: background-color 1s ease;
  }
  
  .color-white {
    color: $black;
    background-color: $white-bg;
  }
  
  .color-black {
    background-color: $dark-grey;
    color: $white-text;
  }
  