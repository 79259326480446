.home-main {
  box-sizing: border-box;
}

.home-section-1 {
  overflow-y: hidden;
  padding-bottom: 50px;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  text-align: center;
  .sect-1-container {
    max-width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .txt-1 {
      .white-txt {
        padding: 0;
        margin-bottom: 0;
        overflow-y: hidden;
        color: $white-text;
        font-size: $largeHeading-vw - 0.5;
        font-weight: $bold;
        word-spacing: -7px;

        .txt-1-yellow-txt{
          color: $yellow !important;
        }
      }
    }
    .txt-2 {
      .yellow-txt {
        margin-bottom: 0;
        overflow-y: hidden;
        color: $yellow;
        font-size: $largeHeading-vw - 0.5;
        font-weight: $bold;
      }
    }

    // to add fade up effect to the hollow text

    @keyframes zoomIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .txt-3 {
      .hollow-txt {
        margin-bottom: 0;
        overflow-y: auto;
        color: transparent;
        font-size: $largeHeading-vw - 0.5;
        font-weight: $bold;
        word-spacing: -17px;
        -webkit-text-stroke: 2px $white-text;
        -webkit-background-clip: text; //to send text to the background
        background-image: radial-gradient(
          closest-side,
          $yellow 100%,
          transparent 0
        ); //for the yellow ball in hollow text
        background-repeat: no-repeat;
        background-size: 400px 400px;
        animation: zoomIn 2s ease-in-out;
      }
    }

    // to add fade down effect to the scroller text

    @keyframes fadeDown {
      from {
        opacity: 0;
        transform: translateY(-100%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .scroller {
      overflow-y: hidden;
      padding-top: 60px;
      padding-bottom: 5px;
      text-align: center;
      animation: fadeDown 1.5s ease-in-out;
      .scroller-text {
        color: $white-text;
        font-size: $smallFont + 2;
        font-weight: $medium;
        margin-bottom: 5px;
      }
      .i {
        font-size: $xsmallHeading;
        font-weight: $thin;
        color: $white-text;
        transition: all 0.5s ease-in-out;
      }
      .i:hover {
        color: $yellow;
        transform: translateY(10px);
      }
    }
  }
}
.new-layout-container {
  position: relative;

  .new-layout-heading {
    display: inline-block;
    position: relative;

    .new-layout-word {
      display: inline-block;
      position: absolute;
    }

    .design-word {
      position: relative;
    }

    .develop-word {
      position:relative;
      top: 4rem;
      left: 6rem;
      color: $yellow !important;
    }
  }
}

.new-layout-dominate {
  position: relative;
  top: 4rem;
  left: 8rem;
}

@media (max-width: 1024px) {
  .home-section-1 {
    .sect-1-container {
      max-width: fit-content;
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw;
        }
      }
      .txt-2 {
        .yellow-txt {
          font-size: $largeHeading-vw;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw - 0.2;
        }
      }
    }
    .scroller {
      padding-top: 40px !important;
    }
  }
}

// to disable hollow text animation for screen smaller than 992px
@media (max-width: 991px) {
  .home-section-1 {
    .txt-3 {
      .hollow-txt {
        background-image: radial-gradient(
          closest-side,
          transparent 100%,
          transparent 0
        ) !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .home-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 2;
          word-spacing: 0;
        }
      }
      .txt-2 {
        .yellow-txt {
          font-size: $largeHeading-vw + 2;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2;
          word-spacing: 0;
          color: $white-text !important;
          -webkit-text-stroke: 0px $white-text !important;
          opacity: 1 !important;
        }
      }
      .scroller {
        .scroller-text {
          font-size: $smallFont;
        }
        .i {
          font-size: $xsmallHeading - 2;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .home-section-1 {
    .sect-1-container {
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2.5;
          letter-spacing: 2px;
          -webkit-text-stroke: 1px $white-text;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .home-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 2.5;
        }
      }
      .txt-2 {
        .yellow-txt {
          font-size: $largeHeading-vw + 2.5;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2.5;
        }
      }
      .scroller {
        .scroller-text {
          font-size: $smallFont - 2;
        }
        .i {
          font-size: $xsmallHeading - 4;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .home-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-2 {
        .yellow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .home-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-2 {
        .yellow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
    }
  }
}

// section1 styling ends here
// section2 styling starts here

.home-section-2 {
  background-color: $dark-grey;
  padding-bottom: 20px;
  .sect-2-container {
    overflow-y: hidden;
    padding: 50px;
  }
  .row {
    display: flex;
    .left-section {
      margin-top: 70px;
      .left-txt {
        p,
        .read-more-left-txt {
          text-align: justify;
          color: $black;
          font-size: $mediumFont;
          font-weight: $regular !important;
        }
        .btn {
          margin-top: 0 !important;
        }
      }
      .left-btn {
        .btn {
          transition: all 0.5s ease-in-out;

          .btn-link {
            text-decoration: none;
            color: $black;
            font-size: $smallFont;
            font-weight: $semi-bold - 100;
          }
        }
      }
    }
    .right-section {
      .right-img {
        width: 100%;
        height: auto;
      }
    }
  }
}

@media (max-width: 1199px) {
  .home-section-2 {
    .sect-2-container {
      .row {
        .left-section {
          margin-top: 0;
          .left-txt {
            p, .read-more-left-txt {
              font-size: $mediumFont - 1;
            }
          }
          .left-btn {
            .btn {
              font-size: $smallFont - 1;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .home-section-2 {
    .sect-2-container {
      .row {
        padding-top: 20px;
        .left-section {
          margin-top: 0;
          .left-txt {
            p, .read-more-left-txt {
              font-size: $mediumFont - 3;
            }
          }
          .left-btn {
            .btn {
              font-size: $smallFont - 3;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .home-section-2 {
    .sect-2-container {
      padding: 50px 20px;
      .row {
        flex-direction: column !important;
        .left-section {
          padding-top: 20px;
          .left-txt {
            .left-btn {
              text-align: center;
            }
          }
        }
        .child:first-child {
          order: 1;
        }
        .child:last-child {
          order: 0;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .home-section-2 {
    .sect-2-container {
      .row {
        .left-section {
          .left-txt {
            p, .read-more-left-txt {
              font-size: $mediumFont - 5;
            }
          }
        }
      }
    }
  }
}

// Section-2 styling ends here
// Section-3 styling begins here

.home-section-3 {
  overflow-y: hidden;
  background-color: $dark-grey;
  padding: 50px;
  .sect-3-container {
    .sect-3-heading {
      color: $white-text;
      text-align: center;
      opacity: 0.5 !important;
      font-size: 1.5rem;
    }
    .sect-3-title {
      color: $yellow;
      font-weight: $bold;
      font-size: $largeHeading;
      text-align: center;
      padding: 20px 0 0 0;
    }
    .sect-3-txt {
      color: $white-text;
      text-align: center;
      font-size: $heading1;
      font-weight: $thin;
      padding: 20px 0;
    }
    .container {
      align-items: center;
      justify-content: space-between !important;
      .portfolio-row {
        .portfolio {
          img {
            padding: 10px;
            width: 100%;
          }
        }
      }
    }
    .projects {
      padding-top: 50px;
      text-align: center;
      font-size: $largeHeading;
      .projects-link {
        text-decoration: none;
        .i {
          color: $yellow;
          font-size: $mediumHeading !important;
        }
        p {
          color: $yellow;
          font-size: $xsmallHeading;
          font-weight: $thin;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .home-section-3 {
    .sect-3-container {
      .sect-3-title {
        font-size: $mediumHeading !important;
      }
      .sect-3-txt {
        font-size: $heading1 - 1 !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .home-section-3 {
    padding: 50px 10px 0px 10px;
    .sect-3-container {
      .portfolio-row {
        display: flex;
        flex-direction: column;
      }
      .sect-3-txt {
        text-align: justify;
      }
    }
  }
}

@media (max-width: 425px) {
  .home-section-3 {
    .sect-3-container {
      .sect-3-title {
        font-size: $mediumHeading - 5 !important;
      }
      .sect-3-txt {
        font-size: $mediumFont - 3 !important;
      }
    }
  }
}

@media (max-width: 375px) {
  .home-section-3 {
    .sect-3-container {
      .sect-3-title {
        font-size: $smallHeading;
      }
      .sect-3-txt {
        font-size: $mediumFont - 2;
      }
    }
  }
}

// section-3 styling ends here
// section-4 styling starts here

.home-section-4 {
  .sect-4-container {
    .black-panel {
      padding: 50px;
      .black-panel-row {
        .black-panel-left {
          .black-panel-txt,
          .read-more-left-txt {
            padding-top: 80px;
            padding-right: 130px;
            font-size: $largeFont + 1 !important;
            font-weight: $regular !important;
            text-align: justify;
          }
          .read-more-left-txt {
            padding-top: 0 !important;
          }
          .home-sec-4-btn {
            background-color: transparent;
            outline: none;
            color: $yellow;
            border: none;
            font-size: $mediumFont + 1;
            cursor: none;
          }
        }
        .black-panel-right {
          text-align: center;
          img {
            width: 100% !important;
            vertical-align: middle !important;
          }
        }
      }
    }
    .white-panel {
      padding: 0px 50px 60px 50px;
      .white-panel-left {
        .white-panel-heading {
          color: $yellow;
          text-align: left;
          font-size: mediumHeading !important;
          font-weight: $semi-bold;
          // opacity: 0.5 !important;
        }
        .white-panel-title {
          padding: 0px 0 0 0;
          color: $yellow;
          text-align: left;
          font-size: $largeHeading;
          font-weight: $bold;
        }
        .white-panel-txt {
          padding: 0px 0;
          color: $black;
          justify-content: baseline;
          font-size: $heading1;
          font-weight: $regular !important;
        }

      .grey-panel-section {
        padding: 0px 1;
        color: aquamarine;
        justify-content: baseline;
        font-size: $headerLinkFont;
        font-weight: 100px;
      }
      }
      .white-panel-right {
        position: relative;
        .projects-link {
          position: absolute;
          bottom: 0;
          right: 0;
          .i {
            color: $yellow;
            font-size: $largeHeading !important;
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .home-section-4 {
    .sect-4-container {
      .black-panel {
        .black-panel-row {
          .black-panel-left {
            .black-panel-txt,
            .read-more-left-txt {
              font-size: $regularFont-1;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .home-section-4 {
    .sect-4-container {
      .black-panel {
        .black-panel-row {
          .black-panel-left {
            .black-panel-txt,
            .read-more-left-txt {
              padding-top: 30px;
              font-size: $largeFont - 1 !important;
            }
            .read-more-left-txt {
              padding-top: 10px !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .home-section-4 {
    .sect-4-container {
      .black-panel {
        padding: 40px;
        .black-panel-row {
          .black-panel-left {
            .black-panel-txt,
            .read-more-left-txt {
              padding-top: 50px !important;
              font-size: $largeFont - 2 !important;
              font-weight: $regular !important;
            }
            .read-more-left-txt {
              padding-top: 10px !important;
            }
          }
          .black-panel-right {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 981px) {
  .home-section-4 {
    .sect-4-container {
      .black-panel {
        padding: 60px;
        .black-panel-row {
          .black-panel-left {
            .black-panel-txt,
            .read-more-left-txt {
              padding-top: 30px !important;
              font-size: $largeFont - 3 !important;
              font-weight: $regular !important;
            }
            .read-more-left-txt {
              padding-top: 10px !important;
            }
          }
          .black-panel-right {
            img {
              padding-top: 50px !important;
              width: 100%;
            }
          }
        }
      }
      .white-panel {
        .white-panel-left {
          .white-panel-title {
            font-size: $mediumHeading;
          }
          .white-panel-txt {
            padding: 20px 0;
            color: $black;
            text-align: justify;
            font-size: $regularFont-1;
            font-weight: $regular !important;
          }
        }
        .white-panel-right {
          position: relative;
          .projects-link {
            position: absolute;
            bottom: 0;
            right: 0;
            .i {
              color: $yellow;
              font-size: $mediumHeading !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 891px) {
  .home-section-4 {
    .sect-4-container {
      .black-panel {
        padding: 60px;
        .black-panel-row {
          .black-panel-left {
            .black-panel-txt,
            .read-more-left-txt {
              padding-top: 30px !important;
              font-size: $largeFont - 3;
              font-weight: $regular !important;
            }
            .read-more-left-txt {
              padding-top: 10px !important;
            }
          }
          .black-panel-right {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 890px) {
  .home-section-4 {
    .sect-4-container {
      .black-panel {
        padding: 60px;
        .black-panel-row {
          .black-panel-left {
            .black-panel-txt,
            .read-more-left-txt {
              padding-top: 0px !important;
              font-size: $largeFont - 4;
              font-weight: $regular !important;
            }
          }
          .black-panel-right {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .home-section-4 {
    .sect-4-container {
      .black-panel {
        padding: 0px 10px 50px 10px;
        .black-panel-row {
          .black-panel-left {
            .black-panel-txt,
            .read-more-left-txt {
              padding-top: 70px !important;
              padding-right: 0 !important;
              font-size: $largeFont - 4;
              font-weight: $regular !important;
            }
            .read-more-left-txt {
              padding-top: 10px !important;
            }
          }
          .black-panel-right {
            img {
              width: 100%;
            }
          }
        }
        .child:first-child {
          order: 1;
        }
        .child:last-child {
          order: 0;
        }
      }
      .white-panel {
        padding: 0px 10px 50px 10px !important;
        .white-panel-left {
          .white-panel-heading {
            text-align: center;
          }
          .white-panel-title {
            text-align: center;
          }
        }
        .white-panel-right {
          overflow-x: visible !important;
          .projects-link {
            .i {
              color: $yellow;
              font-size: $mediumHeading !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .home-section-4 {
    .sect-4-container {
      text-align: center !important;
      .black-panel {
        .black-panel-row {
          .black-panel-right {
            img {
              width: 100%;
            }
          }
        }
        .child:first-child {
          order: 1;
        }
        .child:last-child {
          order: 0;
        }
      }
      .white-panel {
        .white-panel-right {
          .projects-link {
            position: relative !important;
            .i {
              color: $yellow;
              font-size: $mediumHeading !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .home-section-4 {
    .sect-4-container {
      .white-panel {
        .white-panel-left {
          .white-panel-txt {
            font-size: $regularFont;
            font-weight: $regular !important;
          }
        }
        .white-panel-right {
          .projects-link {
            .i {
              color: $yellow;
              font-size: $mediumHeading !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .home-section-4 {
    .sect-4-container {
      .black-panel {
        .black-panel-row {
          .black-panel-left {
            .black-panel-txt,
            .read-more-left-txt {
              font-size: $largeFont - 5;
              font-weight: $regular !important;
            }
          }
        }
      }
      .white-panel {
      }
    }
  }
}

// section-4 styling ends here
// section-5 styling starts here

// styling for larger screens transition

.home-section-5 {
  padding-bottom: 0px;
  .main-div-2 {
    display: none !important;
  }
  .main-div-3 {
    display: none !important;
  }
}

.title-txt {
  color: #000;
}

.mainDiv {
  padding-bottom: 200px;
  position: relative;
  opacity: 1;
  min-height: 600px;
  width: 100%;
  transition: all 0.5s ease !important;
}

/* Create four equal columns that floats next to each other */
.column {
  min-height: 600px; /* Should be removed. Only for demonstration */
  border: 0.5px solid $white-bg;
  opacity: 1;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 600px;
}

.description {
  position: relative;
  color: $white-text;
  font-weight: $semi-bold !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 90px 20px 90px 20px;
}

.description span h2 {
  font-size: $mediumFont;
  font-weight: $semi-bold !important;
  color: $yellow;
  opacity: 0.8;
}

.descp-lik {
  .i {
    position: absolute;
    color: $yellow !important;
    font-size: $mediumHeading - 2 !important;
    transition: all 0.5s ease-in-out;
  }
  .i:hover {
    color: $white-bg !important;
    transform: translateX(15px);
  }
}

.main-div {
  .row {
    position: relative;
    overflow: hidden;
    // background-color: $black-grey;
    // min-height: 600;

    .parentBox {
      overflow: hidden;
      min-height: 600px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      margin: 0;
      padding: 0;
      background-color: gray;
    }

    .column {
      .column1,
      .column2,
      .column3,
      .column4 {
        .description {
          .field-desc {
            color: $white-text;
            font-size: $mediumFont;
            font-weight: $regular !important;
            padding-top: 30px;
            text-align: justify;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;

            .bold {
              color: $yellow;
              font-weight: $heavy-bold-2 !important;
            }
            .light {
              color: $yellow;
              font-weight: $bold;
            }
            .italic {
              color: $yellow;
              font-weight: $bold;
              font-style: italic;
            }
            .regular {
              color: $yellow;
              font-weight: $semi-bold;
            }
          }
          .descp-link {
            .i {
              position: absolute;
              bottom: 90px;
              color: $yellow !important;
              font-size: $mediumHeading - 2 !important;
              transition: all 0.5s ease-in-out;
            }
            .i:hover {
              color: $white-bg !important;
              transform: translateX(15px);
            }
          }
        }
      }
      .column1:hover,
      .column2:hover,
      .column3:hover,
      .column4:hover {
        .description {
          .field-desc {
            opacity: 1;
          }
        }
      }
    }
  }
}

#image0 {
  position: absolute;
  top: 0;
  opacity: 1;
  left: 0;
  height: auto;
}

#image1 {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
}

#image2 {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
}

#image3 {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
}

.column1 {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  border-right: 1px solid $white-bg;
}

.image1,
.image2,
.image3,
.image4 {
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 600px;
  width: 25%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
  -webkit-transition: transform 0.2s, width 0.4s ease-in-out;
  -moz-transition: transform 0.2s, width 0.4s ease-in-out;
  -o-transition: transform 0.2s, width 0.4s ease-in-out;
  transition: transform 0.2s, width 0.4s ease-in-out;
}

.column2 {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  border-right: 1px solid white;
}
.column3 {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  border-right: 1px solid white;
}

.column4 {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

// to disable animation for smaller screens

@media (max-width: 991px) {
  .column1,
  .column2,
  .column3,
  .column4 {
    transition: none !important;
  }
}

// styling for section on smaller screens
@media (max-width: 991px) {
  .home-section-5 {
    .main-div {
      display: none !important;
    }
    .main-div-2 {
      display: block !important;
      .container-section-5-2 {
        .section-5-2-row {
          .services-section {
            .services-section-row {
              padding: 30px 0;
              .services-content-img {
                margin: auto;
                padding: 0;
                .left-img {
                  text-align: center;
                  img {
                    width: 100%;
                    height: auto;
                  }
                }
              }
              .services-content {
                .description {
                  padding: 0;
                  .desc-title {
                    color: $yellow;
                    text-align: left;
                    font-size: 1.5rem !important;
                    opacity: 0.7 !important;
                  }
                  span h2 {
                    font-size: $mediumFont;
                    font-weight: $semi-bold !important;
                    color: $black;
                    opacity: 0.8;
                  }
                  .field-desc {
                    color: $black;
                    font-size: $mediumFont;
                    font-weight: $regular !important;
                    text-align: justify;

                    .bold {
                      color: $yellow;
                      font-weight: $heavy-bold-2;
                    }
                    .light {
                      color: $yellow;
                      font-weight: $bold;
                    }
                    .italic {
                      color: $yellow;
                      font-weight: $bold;
                      font-style: italic;
                    }
                    .regular {
                      color: $yellow;
                      font-weight: $semi-bold;
                    }
                  }
                  .desc-link {
                    text-decoration: none;
                    .desc-link-title {
                      border: 2px solid $yellow;
                      color: $light-grey !important;
                      padding: 5px 17px 3px 5px;
                      width: max-content;
                      font-size: $largeFont !important;
                      transition: all 0.5s ease-in-out;
                      .i {
                        padding-left: 10px;
                        transition: all 0.3s ease-in-out;
                      }
                    }
                    .desc-link-title:hover {
                      border: 2px solid $yellow;
                      color: $white-text !important;
                      padding: 5px 17px 3px 5px;
                      width: max-content;
                      background-color: $yellow;
                      font-size: $largeFont !important;
                      .i {
                        transform: translateX(15px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .home-section-5 {
    .main-div-2 {
      .container-section-5-2 {
        .section-5-2-row {
          .services-section {
            .services-section-row {
              .services-content {
                .description {
                  padding-top: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .home-section-5 {
    .main-div {
      display: none !important;
    }
    .main-div-2 {
      display: none !important;
    }
    .main-div-3 {
      display: block !important;
    }
  }
}

@media (max-width: 575px) {
  .home-section-5 {
    .main-div {
      display: none !important;
    }
    .main-div-3 {
      display: block !important;
      .container-section-5-3 {
        .section-5-3-row {
          .services-section {
            .services-section-row {
              .services-content-img {
                margin: auto;
                padding: 0;
                .left-img {
                  text-align: center;
                  img {
                    width: 100%;
                    height: auto;
                  }
                }
              }
              .services-content {
                margin: auto;
                padding: 0;
                .description {
                  padding: 0;
                  .bg-img {
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    padding: 30px 20px;
                    .desc-title {
                      color: $yellow;
                      text-align: left;
                      font-size: 1.5rem !important;
                      opacity: 0.9 !important;
                    }
                    span h2 {
                      font-size: $mediumFont;
                      font-weight: $semi-bold !important;
                      color: $white-text;
                      opacity: 0.8;
                    }
                    .field-desc {
                      color: $white-text;
                      font-size: $mediumFont;
                      font-weight: $regular !important;
                      text-align: justify;

                      .bold {
                        color: $yellow;
                        font-weight: $heavy-bold-2;
                      }
                      .light {
                        color: $yellow;
                        font-weight: $bold;
                      }
                      .italic {
                        color: $yellow;
                        font-weight: $bold;
                        font-style: italic;
                      }
                      .regular {
                        color: $yellow;
                        font-weight: $semi-bold;
                      }
                    }
                    .desc-link {
                      text-decoration: none;
                      .desc-link-title {
                        color: $yellow !important;
                        padding: 5px 17px 3px 5px;
                        width: max-content;
                        font-size: $largeFont !important;
                        transition: all 0.5s ease-in-out;
                        .i {
                          padding-left: 10px;
                          transition: all 0.3s ease-in-out;
                        }
                      }
                      .desc-link-title:hover {
                        color: $white-text !important;
                        padding: 5px 17px 3px 5px;
                        width: max-content;
                        font-size: $largeFont !important;
                        .i {
                          transform: translateX(15px);
                          color: $yellow !important;
                        }
                      }
                    }
                  }
                  .desc-details {
                    padding: 20px 20px 0 20px;
                    .field-desc {
                      color: $black;
                      font-size: $mediumFont;
                      font-weight: $regular !important;
                      text-align: justify;
                    }
                    .desc-link {
                      text-decoration: none;
                      .desc-link-title {
                        border: 2px solid $yellow;
                        color: $light-grey !important;
                        padding: 5px 17px 3px 5px;
                        width: max-content;
                        font-size: $largeFont !important;
                        transition: all 0.5s ease-in-out;
                        .i {
                          padding-left: 10px;
                          transition: all 0.3s ease-in-out;
                        }
                      }
                      .desc-link-title:hover {
                        border: 2px solid $yellow;
                        color: $white-text !important;
                        padding: 5px 17px 3px 5px;
                        width: max-content;
                        background-color: $yellow;
                        font-size: $largeFont !important;
                        .i {
                          transform: translateX(15px);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .home-section-5 {
    .main-div-3 {
      .container-section-5-3 {
        .section-5-3-row {
          .services-section {
            .services-section-row {
              .services-content {
                .description {
                  padding-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// section-5 styling ends here
// section-6 styling starts here

.home-section-6 {
  padding: 50px;
  .sect-6-container {
    .row {
      .left-txt {
        padding-top: 60px;
        .title {
          text-align: left;
          p {
            font-size: $largeHeading-2;
            font-weight: $semi-bold !important;
            color: $yellow !important;
          }
        }
        .content {
          p {
            font-size: $heading1 !important;
            font-weight: $regular !important;
            color: $black;
            text-align: justify;
            padding-right: 60px !important;
          }
        }
      }
      .right-img {
        img {
          border-radius: 15px;
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .home-section-6 {
    padding: 50px 50px 0 50px;
    .sect-6-container {
      .row {
        .left-txt {
          .title {
            text-align: left;
            p {
              font-size: $largeHeading;
            }
          }
        }
        .right-img {
          img {
            width: 100% !important;
            height: auto !important;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px),
  (min-width: 768px) and (max-width: 768px),
  (min-width: 991px) and (max-width: 991px) {
  .home-section-6 {
    .sect-6-container {
      .row {
        .left-txt {
          padding-top: 0px !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .home-section-6 {
    padding: 50px 10px 50px 10px;
    .sect-6-container {
      .row {
        .left-txt {
          .title {
            text-align: left;
            p {
              font-size: $largeHeading - 5;
            }
          }
          .content {
            p {
              font-size: $largeFont - 2 !important;
            }
          }
        }
        .right-img {
          img {
            width: 100% !important;
            height: auto !important;
          }
        }
        .child:first-child {
          order: 1;
        }
        .child:last-child {
          order: 0;
        }
      }
    }
  }
}

@media (max-width: 660px) {
  .home-section-6 {
    .sect-6-container {
      .row {
        .left-txt {
          .content {
            p {
              padding-right: 0px !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .home-section-6 {
    .sect-6-container {
      .row {
        .left-txt {
          .title {
            text-align: left;
            p {
              font-size: $largeHeading - 5;
            }
          }
          .content {
            p {
              font-size: $regularFont !important;
            }
          }
        }
        .right-img {
          img {
            width: 100% !important;
            height: auto !important;
          }
        }
        .child:first-child {
          order: 1;
        }
        .child:last-child {
          order: 0;
        }
      }
    }
  }
}

// section-6 styling ends here
// section-7 styling starts here

.home-section-7 {
  background-color: $dark-grey;
  padding: 100px 60px 50px 60px;
  margin-top: -8%;
  text-align: center;
  .sect-7-container {
    .row {
      display: flex !important;

      .counter {
        .counter-number {
          color: $yellow;
          font-size: $mediumHeading !important;
          font-weight: $bold !important;
        }
        .counter-title {
          color: $white-text;
          font-size: $largeHeading - 4 !important;
          font-weight: $bold !important;
        }
      }
    }
  }
}

@media (min-width: 2000px) {
  .home-section-7 {
    padding: 230px 60px 30px 60px;
  }
}

@media (min-width: 2500px) {
  .home-section-7 {
    padding: 230px 60px 30px 60px;
  }
}

@media (max-width: 1024px) {
  .home-section-7 {
    .sect-7-container {
      .row {
        .counter {
          .counter-number {
            padding-top: 20px;
            font-size: $mediumHeading - 2 !important;
          }
          .counter-title {
            padding-top: 20px;
            font-size: $largeHeading - 6 !important;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px),
  (min-width: 768px) and (max-width: 768px),
  (min-width: 991px) and (max-width: 991px) {
  .home-section-7 {
    margin-top: 0 !important;
  }
}

@media (max-width: 767px) {
  .home-section-7 {
    background-color: $dark-grey;
    padding: 50px 0px 30px 0px !important;
    margin-top: 0;
    .sect-7-container {
      .row {
        display: flex !important;
        color: $yellow;
        text-align: center !important;

        .counter {
          .counter-number {
            padding: 15px 0 0 0;
            font-size: $mediumHeading !important;
            font-weight: $bold !important;
          }
          .counter-title {
            padding: 15px 0 0 0;
            font-size: $mediumHeading + 2 !important;
            font-weight: $bold !important;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .home-section-7 {
    background-color: $dark-grey;
    padding: 50px 60px 30px 60px;
    margin-top: 0;
    .sect-7-container {
      .row {
        text-align: center !important;
        display: flex !important;
        color: $yellow;

        .counter {
          .counter-number {
            padding: 0 0 0 0;
            font-size: $mediumHeading - 2 !important;
            font-weight: $semi-bold !important;
          }
          .counter-title {
            padding: 0 0 0 0;
            font-size: $mediumHeading !important;
            font-weight: $semi-bold !important;
          }
        }
      }
    }
  }
}

// section-7 styling ends here
// section-8 styling starts here

.home-section-8 {
  padding: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .sect-8-container {
    .slick-prev,
    .slick-next {
      top: 46.5% !important;
    }
    .header {
      text-align: center;
      color: $white-text;
      font-weight: $regular !important;
      font-size: $mediumHeading + 1 !important;
    }
    .testimonial {
      .title {
        padding-top: 30px;
        color: $white-text;
        font-weight: $semi-bold;
        text-align: center;
        .i {
          color: $yellow;
          font-size: $xsmallFont - 7;
          padding: 0 2px;
        }
      }
      .content {
        padding: 30px;
        color: $white-text;
        text-align: justify;
        text-align-last: center;
        h6 {
          font-size: $heading2 !important;
          font-weight: $regular !important;
          line-height: 1.5rem !important;
        }
      }
      .owner {
        padding-top: 30px;
        color: $white-text;
        text-align: center;
        h5 {
          font-weight: $medium !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .home-section-8 {
    padding: 50px 10px;
    .sect-8-container {
      .slick-prev,
      .slick-next {
        top: 50% !important;
      }
      .testimonial {
        .content {
          text-align: justify;
        }
      }
    }
  }
}

// section-8 styling ends here
// section-9 styling starts here

.home-section-9 {
  background-color: $dark-grey;
  padding: 35px 50px;
  padding-bottom: 60px;
  text-align: center;
  .sect-9-container {
    .header {
      text-align: center;
      color: $white-text;
      font-weight: $semi-bold;
      padding-top: 30px;
    }
    .content {
      padding-top: 15px;
      color: $white-text;
      text-align: center;
      p {
        font-size: $smallFont !important;
        font-weight: $regular !important;
      }
    }
    .sub-form {
      padding-top: 0px;
      .form-label {
        text-align: center;
        color: $white-text;
        font-size: $smallHeading - 3;
        font-weight: $regular;
      }
      .email-field {
        padding-top: -10px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $yellow !important;
        width: 50%;
        text-align: center;
        font-size: $xsmallHeading + 1;
        text-transform: lowercase;
        color: transparent;
        caret-color: $yellow;
        font-weight: $semi-bold;
        word-spacing: -17px;
        -webkit-text-stroke: 0.75px $light-grey;
        -webkit-background-clip: text; //to send text to the background
        background-image: radial-gradient(
          closest-side,
          $yellow 100%,
          transparent 0
        ); //for the yellow ball in hollow text
        background-repeat: no-repeat;
        background-size: 100px 100px;
        animation: zoomIn 2s ease-in-out;
      }
      input:focus {
        outline: none;
        background-color: transparent;
      }
      input::placeholder{
        color: $light-grey;
        font-size: $xsmallHeading - 1;
        font-weight: $thin;
        text-transform: none;
        word-spacing: 0px;
        -webkit-text-stroke: 0px $light-grey;
      }
      .btn-link {
        text-decoration: none;
        color: $black;
        font-size: $smallFont;
        font-weight: $semi-bold - 100;

        Link {
          padding-top: 20px !important;
        }
      }
    }
  }
}

// to disable hollow text animation for screen smaller than 992px
@media (max-width: 991px) {
  .home-section-9 {
    .sect-9-container {
      .sub-form {
        .email-field {
          background-image: radial-gradient(
            closest-side,
            transparent 100%,
            transparent 0
          ) !important;
          -webkit-text-stroke: 0px $light-grey;
          color: $light-grey;
          outline: none;
          font-weight: $regular !important;
          font-size: $xsmallHeading;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .home-section-9 {
    padding: 40px 30px;
  }
}

@media (max-width: 575px) {
  .home-section-9 {
    .sect-9-container {
      .sub-form {
        .email-field {
          width: auto;
        }
      }
    }
  }
}
