.toggle {
  position: relative;
  width: 60px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  span {
    position: absolute;
    width: 20px;
    height: 2px;
    background: $white-bg;
    transition: 0.5s;
  }

  span:nth-child(1) {
    transform: translateY(-11px);
    width: 20px;
    left: 15px;
    background: $white-bg;
  }

  span:nth-child(2) {
    transform: translateY(11px);
    width: 20px;
    left: 15px;
    background: $white-bg;
  }

  &.active {
    span:nth-child(1) {
      width: 30px;
      transform: translateY(0px) rotate(45deg);
      transition-delay: 0.25s;
      background: $white-bg;
    }

    span:nth-child(2) {
      width: 30px;
      transform: translateY(0px) rotate(315deg);
      transition-delay: 0.125s;
      background: $white-bg;
    }

    span:nth-child(3) {
      transform: translateX(60px);
    }
  }
}

// to adjust the positioning of toggle button on smaller screens
@media (max-width: 1199px) {
  .toggle {
    width: 65px;
    height: 60px;
  }
}

@media (max-width: 991px) {
  .toggle {
    position: relative;
    width: 65px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    span {
      position: absolute;
      width: 20px;
      left: 15px;
      height: 2px;
      background: $white-bg;
      transition: 0.5s;
    }

    span:nth-child(1) {
      transform: translateY(-11px);
      width: 20px;
      left: 10px;
      background: $white-bg;
    }

    span:nth-child(2) {
      transform: translateY(11px);
      width: 20px;
      left: 10px;
      background: $white-bg;
    }
  }
}

@media (max-width: 768px) {
  .toggle {
    position: relative;
    width: 60px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    span {
      position: absolute;
      width: 20px;
      height: 2px;
      left: 15px;
      background: $white-bg;
      transition: 0.5s;
    }

    span:nth-child(1) {
      transform: translateY(-11px);
      width: 20px;
      left: 10px;
      background: $white-bg;
    }

    span:nth-child(2) {
      transform: translateY(11px);
      width: 20px;
      left: 10px;
      background: $white-bg;
    }
  }
}

@media (max-width: 575px) {
  .toggle {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    span {
      position: absolute;
      width: 20px;
      height: 2px;
      left: 7px;
      background: $white-bg;
      transition: 0.5s;
    }

    span:nth-child(1) {
      transform: translateY(-11px);
      width: 20px;
      left: 0px;
      background: $white-bg;
    }

    span:nth-child(2) {
      transform: translateY(11px);
      width: 20px;
      left: 0px;
      background: $white-bg;
    }
  }
}

@media (max-width: 515px) {
  .toggle {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    span {
      position: absolute;
      width: 20px;
      height: 2px;
      left: 5px;
      background: $white-bg;
      transition: 0.5s;
    }

    span:nth-child(1) {
      transform: translateY(-11px);
      width: 20px;
      left: 0px;
      background: $white-bg;
    }

    span:nth-child(2) {
      transform: translateY(11px);
      width: 20px;
      left: 0px;
      background: $white-bg;
    }
  }
}

@media (max-width: 400px) {
  .toggle {
    position: relative;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

@media (max-width: 380px) {
  .toggle {
    position: relative;
    width: 50px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

@media (max-width: 360px) {
  .toggle {
    position: relative;
    width: 50px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

@media (max-width: 330px) {
  .toggle {
    position: relative;
    width: 50px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

@media (max-width: 320px) {
  .toggle {
    position: relative;
    width: 50px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

@media (max-width: 300px) {
  .toggle {
    position: relative;
    width: 50px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}
