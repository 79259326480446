.about-main {
  box-sizing: border-box;
}

// section-1 styling starts here

.about-section-1 {
  background-color: $dark-grey;
  .sect-1-container {
    .panel-1 {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      .panel-1-container {
        max-width: fit-content;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        text-align-last: center;
        .txt-1 {
          .hollow-txt {
            margin-bottom: 0;
            overflow-y: auto;
            color: transparent;
            font-size: $largeHeading-vw - 0.5;
            font-weight: $bold;
            word-spacing: -17px;
            -webkit-text-stroke: 2px $white-text;
            -webkit-background-clip: text; //to send text to the background
            background-image: radial-gradient(
              closest-side,
              $yellow 100%,
              transparent 0
            ); //for the yellow ball in hollow text
            background-repeat: no-repeat;
            background-size: 400px 400px;
            animation: zoomIn 2s ease-in-out;

            .yellow-txt {
              margin-bottom: 0;
              overflow-y: hidden;
              color: $yellow;
              -webkit-text-stroke: 0px $yellow;
              font-weight: $bold;
            }
          }
        }
        .about-desc {
          .desc {
            padding-top: 20px;
            color: $white-text;
            font-weight: $thin !important;
          }
        }
        // to add fade down effect to the scroller text

        @keyframes fadeDown {
          from {
            opacity: 0;
            transform: translateY(-100%);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .scroller {
          overflow-y: hidden;
          padding-top: 50px;
          animation: fadeDown 1.5s ease-in-out;
          padding-bottom: 5px;
          text-align: center;
          .scroller-text {
            color: $white-text;
            font-size: $smallFont + 2;
            font-weight: $medium;
            margin-bottom: 5px;
          }
          .i {
            font-size: $xsmallHeading;
            font-weight: $thin;
            color: $white-text;
            transition: all 0.5s ease-in-out;
          }
          .i:hover {
            color: $yellow;
            transform: translateY(10px);
          }
        }
      }
    }
    .panel-2 {
      padding: 50px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      .panel-2-container {
        overflow-y: hidden;
      }
      .panel-2-row {
        .left-section {
          padding-top: 15px;
          .txt {
            h5 {
              text-align: justify;
              color: $black;
              font-size: $mediumFont;
              font-weight: $regular !important;
              padding-right: 50px;

              .myBtn {
                color: $yellow !important;
                background-color: transparent !important;
                border: none;
              }
            }
          }
        }
        .right-section {
          .right-img {
            border-radius: 30px;
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .panel-3 {
      padding: 50px;
      .panel-3-container {
        border-radius: 30px;
        background-color: $white-bg;
        .panel-3-row {
          padding: 50px 30px;
          .panel-3-txt {
            .title {
              color: $yellow;
              font-weight: $semi-bold;
              text-align: left;
            }
            .desc {
              font-size: $mediumFont - 2;
              font-weight: $medium !important;
              padding-top: 20px;
              color: $black-grey;
              text-align: justify;
            }
          }
          .panel-3-counter {
            .counter-row {
              padding: 0 30px;
              .counters {
                padding-bottom: 30px;
                .counter {
                  color: $yellow !important;
                  font-size: $mediumHeading;
                  font-weight: $semi-bold !important;
                  margin-bottom: 0 !important;
                }
                .title {
                  color: $light-grey;
                  font-size: $largeFont1;
                  font-weight: $semi-bold !important;
                  margin-bottom: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .about-section-1 {
    .sect-1-container {
      .panel-2 {
        .panel-2-container {
          .panel-2-row {
            .left-section {
              padding-top: 0;
              .txt {
                h5 {
                  font-size: $mediumFont - 2;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .about-section-1 {
    .sect-1-container {
      .panel-1 {
        .panel-1-container {
          max-width: fit-content;
          .txt-1 {
            .hollow-txt {
              font-size: $largeHeading-vw - 0.2;
            }
          }
          .scroller {
            padding-top: 40px !important;
          }
        }
      }
      .panel-3 {
        .panel-3-container {
          .panel-3-row {
            .panel-3-counter {
              .counter-row {
                padding: 0 30px;
                .counters {
                  padding-bottom: 30px;
                  .counter {
                    font-size: $mediumHeading;
                  }
                  .title {
                    font-size: $largeFont;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .about-section-1 {
    .sect-1-container {
      .panel-1 {
        .panel-1-container {
          max-width: fit-content;
          .txt-1 {
            .hollow-txt {
              background-image: radial-gradient(
                closest-side,
                transparent 100%,
                transparent 0
              ) !important;
            }
          }
        }
      }
      .panel-2 {
        .panel-2-container {
          .panel-2-row {
            .left-section {
              padding-top: 0;
              .txt {
                h5 {
                  font-size: $smallFont - 2;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .about-section-1 {
    .sect-1-container {
      .panel-2 {
        .panel-2-container {
          .panel-2-row {
            .left-section {
              padding-top: 0;
              .txt {
                h5 {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .about-section-1 {
    .sect-1-container {
      .panel-1 {
        .panel-1-container {
          .txt-1 {
            .hollow-txt {
              font-size: $largeHeading-vw + 2;
              word-spacing: 0;
              color: $white-text;
              border: $white-text;
              -webkit-text-stroke: 0px $white-text !important;
              opacity: 1;
            }
          }
          .scroller {
            .scroller-text {
              font-size: $smallFont;
            }
            .i {
              font-size: $xsmallHeading - 2;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .about-section-1 {
    .sect-1-container {
      .panel-2 {
        padding: 50px 10px;
        .panel-2-row {
          flex-direction: column !important;
          .left-section {
            .txt {
              padding-top: 40px;
              h5 {
                font-size: $mediumFont - 3 !important;
                padding-right: 0;
              }
            }
          }
          .child:first-child {
            order: 1;
          }
          .child:last-child {
            order: 0;
          }
        }
      }
      .panel-3 {
        padding: 50px 10px;
        .panel-3-container {
          border-radius: 30px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          .panel-3-row {
            padding: 50px 10px;
            .panel-3-txt {
              .title {
                text-align: center;
              }
              .desc {
                font-size: $mediumFont - 3 !important;
                padding-top: 20px;
                text-align: justify;
                text-align-last: center;
              }
            }
            .panel-3-counter {
              .counter-row {
                text-align: center;
                // padding: 0 30px;
                .counters {
                  padding-top: 20px;
                  .counter {
                    font-size: $mediumHeading;
                  }
                  .title {
                    font-size: $largeFont;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .about-section-1 {
    .sect-1-container {
      .panel-1 {
        .panel-1-container {
          max-width: fit-content;
          .txt-1 {
            .hollow-txt {
              font-size: $largeHeading-vw + 2.5;
              letter-spacing: 2px;
              -webkit-text-stroke: 1px $white-text;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .about-section-1 {
    .sect-1-container {
      .panel-1 {
        .panel-1-container {
          .txt-1 {
            .hollow-txt {
              font-size: $largeHeading-vw + 2.5;
            }
            .scroller {
              .scroller-text {
                font-size: $smallFont - 2;
              }
              .i {
                font-size: $xsmallHeading - 4;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .about-section-1 {
    .sect-1-container {
      .panel-1 {
        .panel-1-container {
          .txt-1 {
            .hollow-txt {
              font-size: $largeHeading-vw + 3;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .about-section-1 {
    .sect-1-container {
      .panel-1 {
        .panel-1-container {
          .txt-1 {
            .hollow-txt {
              font-size: $largeHeading-vw + 3;
            }
          }
        }
      }
    }
  }
}

// section-1 styling ends here
// section-2 styling starts here

.about-section-2 {
  padding: 50px;
  background-color: $dark-grey;
  overflow-y: hidden;
  .sect-2-container {
    .sect-2-row {
      .left-section {
        border-radius: 30px;
        .left-img {
          border-radius: 30px;
          width: 100%;
          height: auto;
        }
      }
      .right-section {
        padding-top: 30px;
        .txt {
          h5 {
            text-align: justify;
            color: $white-text;
            font-size: $mediumFont;
            font-weight: $regular !important;
            padding-left: 50px;
            .myBtn {
              color: $yellow !important;
              background-color: transparent !important;
              border: none;
              cursor: none !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .about-section-2 {
    .sect-2-container {
      .sect-2-row {
        .right-section {
          padding-top: 0;
          .txt {
            h5 {
              font-size: $mediumFont - 2;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .about-section-2 {
    .sect-2-container {
      .sect-2-row {
        .right-section {
          padding-top: 0;
          .txt {
            h5 {
              font-size: $smallFont - 2;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .about-section-2 {
    .sect-2-container {
      .sect-2-row {
        .right-section {
          padding-top: 0;
          .txt {
            h5 {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .about-section-2 {
    padding: 50px 10px;
    .sect-2-row {
      flex-direction: column !important;
      .right-section {
        .txt {
          padding-top: 40px;
          h5 {
            font-size: $mediumFont - 3 !important;
            padding-left: 0 !important;
          }
        }
      }
    }
  }
}

// section-3 styling starts here

// section-4 styling starts here

.about-section-3 {
  background-color: $dark-grey;
  padding: 50px 0;
  text-align: center;
  .about-us {
    h5 {
      padding-top: 30px;
      line-height: 2rem;
      text-align: justify;
      text-align-last: center;
      color: $white-text;
      font-weight: $regular;
      // word-spacing: -2px;
    }
  }
}

@media (max-width: 767px) {
  .about-section-3 {
    padding: 0px 10px 30px 10px;
    .about-us {
      h5 {
        font-size: $mediumFont - 3 !important;
        line-height: 20px !important;
      }
    }
  }
}
