.rjs-cursor {
    width: 20px;
    height: 20px;
    position: fixed; /* Fixed position.. */
    top: 0; /* at the top left.. */
    left: 0;
    z-index: 999999; /* above everything else. */
    pointer-events: none; /* Cant't be clicked. */
    transition: none; /* Cursor is always accurate */
    opacity: 0; /* Hidden by default */
    overflow-x: visible;

    pointer-events: none;
  }
  
  .rjs-cursor-icon {
    /* Styling for the visible; part */
    width: 100%;
    height: 100%;
    border-radius: 100%; /* Circle */
    /* background-color: rgba(123, 123, 123, 0.7); */
    background-color: $yellow;
    border: 0.5px solid $yellow;
    overflow-x: visible;
  }
  
  .rjs-cursor.rjs_cursor_visible {
    opacity: 1;
    overflow-x: visible;
  }
  .rjs-cursor.rjs_cursor_hidden {
    opacity: 0;
    overflow-x: visible;
  }
  
  /* Display the cursors at the correct time */
  * {
    cursor: none;
  }
  
  @media (pointer: none), (pointer: coarse) {
    #rjs_cursor,
    #rjs_cursor .rjs-cursor-icon {
      display: none !important;
      visibility: hidden;
      opacity: 0;
      overflow-x: visible;
    }
  
    * {
      cursor: none;
    }
  }
  
  /* The body element is required to fill the complete browser. This will be the case on almost every website that has content. Otherwise you could add this as a fallback */
  
  /* hover animation css */
  
  .rjs-cursor-icon {
    /* Earlier styles */
    transition: all 0.2s ease; /* Short & sweet animation */
    transform-origin: 50% 50%;
    overflow-x: visible;
    /* When we use the transform property to e.g. scale an animation, we'll do it from the center and not the top left */
  }
  
  .rjs-cursor.rjs_cursor_hover .rjs-cursor-icon {
    transform: scale(1.5);
    opacity: 0.8;
    overflow-x: visible;
  }
  