.scroll-to-top-button {
  position: fixed;
  bottom: 40px;
  right: 15px;
  visibility: hidden;
  transition: visibility 0.3s ease;
  z-index: 1;
  border: none;
  background-color: transparent;

  .i {
    font-size: $largeHeading;
    color: $yellow;
    opacity: 0.5;
    transition: all 0.5 ease-in-out;
  }
}

.scroll-to-top-button.visible {
  visibility: visible;
}

@media (max-width: 768px) {
  .scroll-to-top-button {
    .i {
      font-size: $mediumHeading;
    }
  }
}
