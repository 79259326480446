  ::-webkit-scrollbar {
    width: 8px;
    background: $black-grey;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: $yellow;
  }  

  .scroll-progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    height: 5px;
    background-color: #fcb61d;
    z-index: 1;
    transition: width 0.5s ease;
  }