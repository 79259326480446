.toggler-close {
  cursor: none !important;
  top: 0px;
  left: 0px;
}

.menu-item-animate {
  animation-name: transformScaling;
  animation-duration: 1s; /* Adjust the duration to match the animation time */
  animation-timing-function: ease-in-out;
}

@keyframes transformScaling {
  0% {
    transform: scaleX(1);
    transform-origin: left center;
  }
  50% {
    transform: scaleX(0);
    transform-origin: left center;
  }
  50.5% {
    transform: scaleX(0);
    transform-origin: right center;
  }
  100% {
    transform: scaleX(1);
    transform-origin: right center;
  }
}


.main-nav {
  padding: 3% 0%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 1;

  .navbar-container {
    width: 100%;
    height: 100%;
    margin: auto;

    .main-nav_inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;

      .navbar-logo {
        width: auto;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        .navbar-logo-img {
          display: flex;
          justify-content: center;
          align-items: center;
          height: auto;
          width: 150px;
        }
      }

      .navbar-logo.visible {
        opacity: 1;
      }

      .main-nav_toggler {
        position: relative;
        width: 40px;
        height: 28px;
        border: none;
        margin-top: 2.5%;
        background-color: transparent;
      }

      .toggler-close:before,
      .toggler-close:after {
        position: absolute;
        top: 45%;
        left: 5%;
        transition-property: all;
        transition-duration: 0.15s;
        transition-timing-function: cubic-bezier(0, 1.07, 1, 2.71);
        transition-delay: 0s;
        display: block;
        width: 70%;
        height: 2px !important;
        content: "";
        background-color: #fff;
        border-radius: 3px;
      }

      .toggler-close:before {
        transform: rotate(45deg);
      }

      .toggler-close:after {
        transform: rotate(-45deg);
      }
    }
  }
}

.main-nav_expandable {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999;

  .main-nav_expandable-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 13px 0;
    background-color: $dark-black;
    z-index: 5;

    .main-nav_expandable-content {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
}

.main-nav_item:not(:last-child) {
  margin-bottom: 15%;
}

.main-nav_link {
  color: $white-text;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: $yellow;
  }
}

.main-nav_expandable:before,
.main-nav_expandable:after {
  position: absolute;
  top: 0;
  left: 0;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
  display: block;
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  transform-origin: left center;
  content: "";
  z-index: 1;
}

.main-nav_expandable:before {
  background-color: $yellow;
}

.main-nav_expandable:after {
  transition-delay: 0.07s;
  background-color: $white-bg;
}

.main-nav_expandable.js-open {
  opacity: 1;
  visibility: visible;

  &:before,
  &:after,
  .main-nav_expandable-inner {
    transform: scaleX(1);
  }

  .main-nav_expandable-inner {
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    transition-delay: 0.14s;
  }

  .main-nav_list {
    padding: 7% 0 0 20%;
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: linear;
    transition-delay: 0.85s;
    opacity: 1;
    visibility: visible;

    .main-nav_item {
      transition-property: all;
      transition-duration: 0.1s;
      transition-timing-function: linear;
      transition-delay: 0s;
      transform: translateY(0);
      opacity: 1;
      visibility: visible;

      .main-nav_link {
        text-decoration: none !important;

        .main-nav_link-title {
          font-size: $largeHeading + 2;
          font-weight: $bold;
          line-height: 50px;
        }
      }
    }

    .main-nav_item:nth-child(1) {
      transition-delay: 0.85s;
    }

    .main-nav_item:nth-child(2) {
      transition-delay: 0.95s;
    }

    .main-nav_item:nth-child(3) {
      transition-delay: 1.05s;
    }

    .main-nav_item:nth-child(4) {
      transition-delay: 1.15s;
    }

    .main-nav_item:nth-child(5) {
      transition-delay: 1.25s;
    }

    .main-nav_item:nth-child(6) {
      transition-delay: 1.35s;
    }

    .main-nav_item:nth-child(7) {
      transition-delay: 1.45s;
    }
  }
  .toggler-close {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.14, 1.01, 0.54, 1.38);
    transition-delay: 0.65s;
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
  }
}

.main-nav_expandable-inner {
  transform: scaleX(0);
  transform-origin: left center;
}

.toggler-close {
  transform: translateX(15px);
  opacity: 0;
  visibility: hidden;
}

.main-nav_list,
.main-nav_item {
  opacity: 0;
  visibility: hidden;
}

@keyframes transformScaling {
  0% {
    transform: scaleX(0);
    transform-origin: left center;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left center;
  }
  50.5% {
    transform: scaleX(1);
    transform-origin: right center;
  }
  100% {
    transform: scaleX(0);
    transform-origin: right center;
  }
}

@media (max-width: 1024px) {
  .main-nav {
    .navbar-container {
      .main-nav_inner {
        .main-nav_toggler {
          margin-top: 2.5%;
        }
        .navbar-logo {
          .navbar-logo-img {
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;
            width: 140px !important;
          }
        }
        .main-nav_expandable.js-open {
          .main-nav_list {
            padding: 10% 0 0 15%;
            transition-property: all;
            transition-duration: 0s;
            transition-timing-function: linear;
            transition-delay: 0.85s;
            opacity: 1;
            visibility: visible;

            .main-nav_item {
              transition-property: all;
              transition-duration: 0.1s;
              transition-timing-function: linear;
              transition-delay: 0s;
              transform: translateY(0);
              opacity: 1;
              visibility: visible;

              .main-nav_link {
                text-decoration: none !important;

                .main-nav_link-title {
                  font-size: $largeHeading - 4;
                  font-weight: $bold;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .main-nav {
    .navbar-container {
      .main-nav_inner {
        .main-nav_toggler {
          margin-top: 1.5%;
        }
        .navbar-logo {
          .navbar-logo-img {
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;
            width: 130px !important;
          }
        }
        .main-nav_expandable.js-open {
          .main-nav_list {
            padding: 10% 0 0 15%;
            transition-property: all;
            transition-duration: 0s;
            transition-timing-function: linear;
            transition-delay: 0.85s;
            opacity: 1;
            visibility: visible;

            .main-nav_item {
              transition-property: all;
              transition-duration: 0.1s;
              transition-timing-function: linear;
              transition-delay: 0s;
              transform: translateY(0);
              opacity: 1;
              visibility: visible;

              .main-nav_link {
                text-decoration: none !important;

                .main-nav_link-title {
                  font-size: $largeHeading - 6;
                  font-weight: $bold;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .main-nav {
    .navbar-container {
      .main-nav_inner {
        .main-nav_toggler {
          margin-top: 1.5%;
        }
        .navbar-logo {
          .navbar-logo-img {
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;
            width: 110px !important;
          }
        }
        .main-nav_expandable.js-open {
          .main-nav_list {
            padding: 20% 0 0 10%;

            .main-nav_item {
              .main-nav_link {
                text-decoration: none !important;

                .main-nav_link-title {
                  font-size: $largeHeading - 7;
                  font-weight: $bold;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .main-nav {
    .navbar-container {
      .main-nav_inner {
        .main-nav_toggler {
          margin-top: 0%;
        }
        .navbar-logo {
          .navbar-logo-img {
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;
            width: 110px !important;
          }
        }
        .main-nav_expandable.js-open {
          .main-nav_list {
            padding: 25% 0 0 10%;

            .main-nav_item {
              .main-nav_link {
                text-decoration: none !important;

                .main-nav_link-title {
                  font-size: $mediumHeading - 4;
                  font-weight: $bold;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .main-nav {
    .navbar-container {
      .main-nav_inner {
        .main-nav_toggler {
          margin-top: 0%;
          .navbar-logo {
            .navbar-logo-img {
              display: flex;
              justify-content: center;
              align-items: center;
              height: auto;
              width: 110px !important;
            }
          }
          .main-nav_expandable.js-open {
            .main-nav_list {
              padding: 25% 0 0 10%;

              .main-nav_item {
                .main-nav_link {
                  text-decoration: none !important;

                  .main-nav_link-title {
                    font-size: $mediumHeading + 2;
                    font-weight: $bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .main-nav {
    .navbar-container {
      .main-nav_inner {
        .main-nav_toggler {
          margin-top: -0.5%;
        }
        .navbar-logo {
          .navbar-logo-img {
            display: flex;
            justify-content: center;
            align-items: center;
            height: auto;
            width: 110px !important;
          }
        }
        .main-nav_expandable.js-open {
          .main-nav_list {
            padding: 25% 0 0 10%;

            .main-nav_item {
              .main-nav_link {
                text-decoration: none !important;

                .main-nav_link-title {
                  font-size: $mediumHeading;
                  font-weight: $bold;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1500px) {
  .main-nav {
    .navbar-container {
      .main-nav_expandable-content {
        // margin-top: 1.5%;
      }
    }
  }
}

@media (min-width: 1700px) {
  .main-nav {
    .navbar-container {
      .main-nav_expandable-content {
        margin-top: 1%;
      }
    }
  }
}

@media (min-width: 2100px) {
  .main-nav {
    .navbar-container {
      .main-nav_expandable-content {
        margin-top: 1.5%;
      }
    }
  }
}

@media (min-width: 2300px) {
  .main-nav {
    .navbar-container {
      .main-nav_expandable-content {
        margin-top: 2%;
      }
    }
  }
}

@media (min-width: 2400px) {
  .main-nav {
    .navbar-container {
      .main-nav_expandable-content {
        margin-top: 2.5%;
      }
    }
  }
}
