.contact-main {
  box-sizing: border-box;
}

.contact-section-1 {
  background-color: $dark-grey;
  overflow-y: hidden;
  padding-bottom: 50px;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  text-align: center;
  .sect-1-container {
    max-width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .txt-1 {
      .hollow-txt {
        margin-bottom: 0;
        overflow-y: hidden;
        color: transparent;
        font-size: $largeHeading-vw - 0.5;
        font-weight: $bold;
        word-spacing: -17px;
        -webkit-text-stroke: 2px $white-text;
        -webkit-background-clip: text; //to send text to the background
        background-image: radial-gradient(
          closest-side,
          $yellow 100%,
          transparent 0
        ); //for the yellow ball in hollow text
        background-repeat: no-repeat;
        background-size: 400px 400px;

        .white-txt {
          color: $yellow;
          -webkit-text-stroke: 2px $yellow;
        }
      }
    }
    .services-desc {
      padding-top: 20px;
      .desc {
        color: $white-text;

        span {
          color: $yellow;
          font-weight: $semi-bold;
        }
      }
    }

    // to add fade down effect to the scroller text

    @keyframes fadeDown {
      from {
        opacity: 0;
        transform: translateY(-100%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .scroller {
      overflow-y: hidden;
      padding-top: 60px;
      padding-bottom: 5px;
      text-align: center;
      animation: fadeDown 1.5s ease-in-out;

      .scroller-text {
        color: $white-text;
        font-size: $smallFont + 2;
        font-weight: $medium;
        margin-bottom: 5px;
      }
      .i {
        font-size: $xsmallHeading;
        font-weight: $thin;
        color: $white-text;
        transition: all 0.5s ease-in-out;
      }
      .i:hover {
        color: $yellow;
        transform: translateY(10px);
      }
    }
  }
}

@media (max-width: 1024px) {
  .contact-section-1 {
    .sect-1-container {
      max-width: fit-content;
      .txt-1 {
        .hollow-txt {
          font-size: $largeHeading-vw - 0.2;
        }
      }
    }
    .scroller {
      padding-top: 40px !important;
    }
  }
}

// to disable hollow text animation for screen smaller than 992px
@media (max-width: 991px) {
  .contact-section-1 {
    .txt-1 {
      .hollow-txt {
        background-image: radial-gradient(
          closest-side,
          transparent 100%,
          transparent 0
        ) !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .contact-section-1 {
    .sect-1-container {
      .txt-1 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2;
          word-spacing: 0;
          color: $white-text !important;
          opacity: 1 !important;
        }
      }
      .scroller {
        .scroller-text {
          font-size: $smallFont;
        }
        .i {
          font-size: $xsmallHeading - 2;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .contact-section-1 {
    .sect-1-container {
      .txt-1 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2.5;
          letter-spacing: 2px;
          -webkit-text-stroke: 1px $white-text;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .contact-section-1 {
    .sect-1-container {
      .txt-1 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2.5;
        }
      }
      .scroller {
        .scroller-text {
          font-size: $smallFont - 2;
        }
        .i {
          font-size: $xsmallHeading - 4;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .contact-section-1 {
    .sect-1-container {
      .txt-1 {
        .hollow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .contact-section-1 {
    .sect-1-container {
      .txt-1 {
        .hollow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
    }
  }
}

// section1 styling ends here
// section2 styling starts here

.contact-section-2 {
  background-color: $dark-grey;
  padding: 50px 0px;
  .sect-1-container {
    .contact-dtls {
      padding: 0 50px;
      overflow-y: auto;
      .wrapper {
        background-color: $black;
        .dtls-title {
          padding: 30px 0;
          text-align: center;
          background-color: $yellow;
          h2 {
            font-weight: $bold !important;
            color: $dark-grey;
            span {
              color: $white-text !important;
            }
          }
        }
        .contact-info {
          padding: 20px 20px 10px 20px;
          .contact-info-row {
            .address {
              text-align: center;
              .i {
                color: $yellow;
                font-size: $smallHeading;
                transition: all 0.5s ease-in-out;
              }
              .i:hover {
                color: $white-bg;
              }
              hr {
                color: $yellow;
                border: 1px solid $yellow;
              }
              .loc {
                color: $white-text;
                font-size: $regularFont;
                text-align: justify;
                span {
                  color: $yellow;
                  font-weight: $semi-bold;
                }
              }
            }
            .phone-dtls {
              text-align: center;
              .i {
                color: $yellow;
                font-size: $smallHeading;
                transition: all 0.5s ease-in-out;
              }
              .i:hover {
                color: $white-bg;
              }
              hr {
                color: $yellow;
                border: 1px solid $yellow;
              }
              .phone {
                color: $white-text;
                font-size: $regularFont;
                text-align: justify;
                span {
                  color: $yellow;
                  font-weight: $semi-bold;
                }
                .bars {
                  color: $yellow;
                  font-weight: $semi-bold;
                }
              }
            }
            .email {
              text-align: center;
              .i {
                color: $yellow;
                font-size: $smallHeading;
                transition: all 0.5s ease-in-out;
              }
              .i:hover {
                color: $white-bg;
              }
              hr {
                color: $yellow;
                border: 1px solid $yellow;
              }
              .mail {
                color: $yellow;
                font-size: $regularFont;
                font-weight: $semi-bold;
              }
              .map {
                padding: 20px 0 10px 0;
                // pointer-events: none;
                iframe {
                  border: 3px double $light-grey;
                  width: 100%;
                  height: auto;
                  // pointer-events: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .contact-section-2 {
    .sect-1-container {
      .contact-dtls {
        padding: 0px 10px !important;
        .wrapper {
          .contact-info {
            .contact-info-row {
              .address {
                text-align: center;
                .i {
                  color: $yellow;
                  font-size: $smallHeading;
                  transition: all 0.5s ease-in-out;
                }
                .i:hover {
                  color: $black-grey;
                }
                hr {
                  color: $yellow;
                  border: 1px solid $yellow;
                }
                .loc {
                  color: $white-text;
                  font-size: $regularFont;
                  text-align: justify;
                  span {
                    // color: $yellow;
                    font-weight: $semi-bold;
                  }
                }
              }
              .phone-dtls {
                padding-top: 30px;
              }
              .email {
                padding-top: 30px;
              }
            }
          }
        }
      }
    }
  }
}
