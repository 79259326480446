.our-work-main {
  box-sizing: border-box;
  .portfolio {
    .sec-5 {
      padding-bottom: 70px;
    }
  }
}

.our-work-section-1 {
  background-color: $dark-grey;
  overflow-y: hidden;
  padding-bottom: 50px;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  text-align: center;
  .sect-1-container {
    max-width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .txt-1 {
      .white-txt {
        padding: 0;
        margin-bottom: 0;
        overflow-y: hidden;
        color: $white-text;
        font-size: $largeHeading-vw - 1;
        font-weight: $bold;
        word-spacing: -11px;

        .yellow-text-span {
          color: $yellow;
        }
      }
    }
    .txt-2 {
      .yellow-txt {
        margin-bottom: 0;
        overflow-y: hidden;
        color: $yellow;
        font-size: $largeHeading-vw - 1;
        font-weight: $bold;

        .white-text-span {
          color: $white-text;
        }
      }
    }
    .txt-3 {
      .hollow-txt {
        margin-bottom: 0;
        overflow-y: hidden;
        color: transparent;
        font-size: $largeHeading-vw - 1;
        font-weight: $bold;
        word-spacing: -17px;
        -webkit-text-stroke: 2px $white-text;
        -webkit-background-clip: text; //to send text to the background
        background-image: radial-gradient(
          closest-side,
          $yellow 100%,
          transparent 0
        ); //for the yellow ball in hollow text
        background-repeat: no-repeat;
        background-size: 400px 400px;
      }
    }

    // to add fade down effect to the scroller text

    @keyframes fadeDown {
      from {
        opacity: 0;
        transform: translateY(-100%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .scroller {
      overflow-y: hidden;
      padding-top: 60px;
      padding-bottom: 5px;
      text-align: center;
      animation: fadeDown 1.5s ease-in-out;

      .scroller-text {
        color: $white-text;
        font-size: $smallFont + 2;
        font-weight: $medium;
        margin-bottom: 5px;
      }
      .i {
        font-size: $xsmallHeading;
        font-weight: $thin;
        color: $white-text;
        transition: all 0.5s ease-in-out;
      }
      .i:hover {
        color: $yellow;
        transform: translateY(10px);
      }
    }
  }
}

@media (max-width: 1024px) {
  .our-work-section-1 {
    .sect-1-container {
      max-width: fit-content;
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw;
        }
      }
      .txt-2 {
        .yellow-txt {
          font-size: $largeHeading-vw;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw - 0.2;
        }
      }
    }
    .scroller {
      padding-top: 40px !important;
    }
  }
}

// to disable hollow text animation for screen smaller than 992px
@media (max-width: 991px) {
  .our-work-section-1 {
    .txt-3 {
      .hollow-txt {
        background-image: radial-gradient(
          closest-side,
          transparent 100%,
          transparent 0
        ) !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .our-work-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 2;
          word-spacing: 0;
        }
      }
      .txt-2 {
        .yellow-txt {
          font-size: $largeHeading-vw + 2;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2;
          word-spacing: 0;
          color: $white-text!important;
          -webkit-text-stroke: 0px $white-text !important;
          opacity: 1 !important;
          word-spacing: 0;
        }
      }
      .scroller {
        .scroller-text {
          font-size: $smallFont;
        }
        .i {
          font-size: $xsmallHeading - 2;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .our-work-section-1 {
    .sect-1-container {
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2.5;
          letter-spacing: 2px;
          -webkit-text-stroke: 1px $white-text;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .our-work-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 2.5;
        }
      }
      .txt-2 {
        .yellow-txt {
          font-size: $largeHeading-vw + 2.5;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2.5;
        }
      }
      .scroller {
        .scroller-text {
          font-size: $smallFont - 2;
        }
        .i {
          font-size: $xsmallHeading - 4;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .our-work-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-2 {
        .yellow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .our-work-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-2 {
        .yellow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
    }
  }
}

// section1 styling ends here
// section2 styling starts here

.our-work-section-2 {
  background-color: $dark-grey;

  .our-work-container-fluid {
    .portfolio-row {
      // position: relative;
      .slick-slide {
        padding: 20px;
      }
      // text-align: center;

      // position: absolute;
      // left: 50%;
      .portfolio {
        text-align: center;
        // max-width: 100%;
        // position: relative;
        img {
          // position: absolute;
          // margin-left: 50%;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

// section2 styling ends here
// section3 styling starts here

.our-work-section-2 {
  display: none;
}

$even-height: 645px;
$odd-height: 475px;

.our-work-section-3 {
  background-color: $dark-grey;
  // position: relative;
  // min-height: 100vh;
  .container-center {
    .sect-3-container {
      align-items: center;
      padding: 0 20px;
      // margin-left: 15%;
      // position: absolute;
      // left: 50%;
      .sect-3-row {
        flex-wrap: wrap;
        .col-even {
          height: $even-height;
          overflow-y: hidden;
        }
        .col-odd {
          height: $odd-height;
          overflow-y: hidden;
        }
        .v-col:nth-child(5) {
          // to bring the 5th image on top
          top: calc(($even-height - $odd-height) * -1);
        }
        .v-col {
          padding: 0;
          border: 0.5px solid transparent;
          position: relative;
          .wrapper {
            img {
              width: 100%;
              height: 100%;
              position: absolute;
            }
            .overlay {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: $dark-black;
              overflow: hidden;
              width: 100%;
              height: 0;
              transition: 0.5s ease;
              opacity: 0.9;

              .overlay-txt {
                color: $yellow;
                font-size: $smallHeading;
                font-weight: $semi-bold;
                position: absolute;
                top: 50%;
                left: 50%;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                text-align: center;
              }
            }
          }
          .wrapper:hover .overlay {
            height: 25%;
          }
        }
      }
    }
  }
}

// defining picture heights for our work section

@media (min-width: 1601px) {
  $even-height: 745px;
  $odd-height: 650px;
  .our-work-section-3 {
    .container-center {
      .sect-3-container {
        .sect-3-row {
          .col-even {
            height: $even-height;
          }
          .col-odd {
            height: $odd-height;
          }
          .v-col:nth-child(5) {
            // to bring the 5th image on top
            top: calc(($even-height - $odd-height) * -1);
          }
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  $even-height: 645px;
  $odd-height: 475px;
  .our-work-section-3 {
    .container-center {
      .sect-3-container {
        .sect-3-row {
          .col-even {
            height: $even-height;
          }
          .col-odd {
            height: $odd-height;
          }
          .v-col:nth-child(5) {
            // to bring the 5th image on top
            top: calc(($even-height - $odd-height) * -1);
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  $even-height: 480px;
  $odd-height: 345px;
  .our-work-section-3 {
    .container-center {
      .sect-3-container {
        .sect-3-row {
          .col-even {
            height: $even-height;
          }
          .col-odd {
            height: $odd-height;
          }
          .v-col:nth-child(5) {
            // to bring the 5th image on top
            top: calc(($even-height - $odd-height) * -1);
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .our-work-section-2 {
    display: block;
  }
  .our-work-section-3 {
    display: none;
  }
}

@media (max-width: 768) {
  $even-height: 350px;
  $odd-height: 245px;
  .our-work-section-3 {
    .container-center {
      .sect-3-container {
        .sect-3-row {
          .col-even {
            height: $even-height;
          }
          .col-odd {
            height: $odd-height;
          }
          .v-col:nth-child(5) {
            // to bring the 5th image on top
            top: calc(($even-height - $odd-height) * -1);
          }
        }
      }
    }
  }
}

// section3 styling ends here
// section4 styling starts here

.our-work-section-4 {
  background-color: $dark-grey;
  padding: 50px 0;
  .sect-4-container {
    padding: 0 20px;
    .sec-4-row {
      .left-txt {
        // styling for neon text
        .glow-txt {
          color: $white-text;
          font-size: $largeHeading;
          font-weight: $semi-bold;
          padding: 18px 30px 30px 30px;
          text-transform: uppercase;
          color: $yellow !important;
          text-shadow: 0 0 5px $yellow, 0 0 5px rgba(229, 255, 0, 0.7),
            0 0 15px rgba(229, 255, 0, 0.5), 0 0 25px rgba(229, 255, 0, 0.3),
            0 0 35px rgba(229, 255, 0, 0.2), 0 0 45px rgba(229, 255, 0, 0.1),
            0 0 55px rgba(229, 255, 0, 0.05);
          animation: animate 3s infinite alternate;

          @keyframes animate {
            40% {
              opacity: 1;
            }
            42% {
              opacity: 0.8;
            }
            43% {
              opacity: 1;
            }
            45% {
              opacity: 0.3;
            }
            46% {
              opacity: 1;
            }
          }
        }
        // styling for neon text ends here
        // styling for running outline animation
        svg text {
          // letter-spacing: 10px;
          // padding: 20px;
          stroke: $yellow;
          // font-size: $largeHeading-5;
          font-size: calc(3.3vw + 3.3vh); /* Responsive font size */
          font-weight: $semi-bold;
          stroke-width: 2;

          animation: textAnimate 3s infinite alternate;
        }

        @keyframes textAnimate {
          0% {
            stroke-dasharray: 0 50%;
            stroke-dashoffset: 20%;
            fill: $yellow;
          }

          100% {
            stroke-dasharray: 50% 0;
            stroke-dashoffstet: -20%;
            fill: $black-grey;
          }
        }
        // styling for running outline animation ends here
      }
      .right-txt {
        .changing-txt {
          color: $white-text;
          font-weight: $medium;
          font-size: $mediumHeading !important;
          text-align: center;
          padding-top: 0px;
          padding-bottom: 40px;

          span {
            color: $yellow;
          }
        }
      }
    }
  }
}

@media (min-width: 2000px) {
  .our-work-section-4 {
    .sect-4-container {
      .sec-4-row {
        .right-txt {
          .changing-txt {
            padding-top: 0;
            // padding-bottom: 60px;
            font-size: $mediumHeading !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1999px) {
  .our-work-section-4 {
    .sect-4-container {
      .sec-4-row {
        .right-txt {
          .changing-txt {
            padding-top: 0px;
            font-size: $mediumHeading !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1700px) {
  .our-work-section-4 {
    .sect-4-container {
      .sec-4-row {
        .right-txt {
          .changing-txt {
            font-size: $mediumHeading;
            // padding-top: 25px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .our-work-section-4 {
    .sect-4-container {
      .sec-4-row {
        .left-txt {
          .glow-txt {
            font-size: $mediumHeading - 2 !important;
          }
        }
        .right-txt {
          .changing-txt {
            font-size: $mediumHeading - 5 !important;
            // text-align: end;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .our-work-section-4 {
    padding: 30px 0 0 0;
    .sect-4-container {
      .sec-4-row {
        .left-txt {
          .glow-txt {
            font-size: $smallHeading !important;
            margin: 0;
          }
          svg text {
            font-size: calc(2.7vw + 2.7vh); /* Responsive font size */
            stroke-width: 1;
          }
        }
        .right-txt {
          .changing-txt {
            font-size: $smallHeading !important;
            // text-align: end;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .our-work-section-4 {
    .sect-4-container {
      .sec-4-row {
        .left-txt {
          .glow-txt {
            text-align: center;
            font-size: $mediumHeading - 2 !important;
            margin: 0;
          }
        }
        .right-txt {
          .changing-txt {
            font-size: $smallHeading !important;
            text-align: justify;
            text-align-last: center;
            padding-top: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .our-work-section-4 {
    padding: 25px 0;
    .sect-4-container {
      .sec-4-row {
        .left-txt {
          .glow-txt {
            text-align: center;
            font-size: $mediumHeading - 3 !important;
            margin: 0;
          }
          svg text {
            // letter-spacing: 10px;
            // padding: 20px;
            stroke: $yellow;
            // font-size: $largeHeading-5;
            font-size: calc(2.5vw + 2.5vh); /* Responsive font size */
            font-weight: $semi-bold;
            stroke-width: 1;

            animation: textAnimate 5s infinite alternate;
          }
        }
        .right-txt {
          .changing-txt {
            font-size: $smallHeading - 2 !important;
            text-align: justify;
            text-align-last: center;
            padding-top: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .our-work-section-4 {
    .sect-4-container {
      .sec-4-row {
        .left-txt {
          .glow-txt {
            text-align: center;
            font-size: $smallHeading !important;
            margin: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .our-work-section-4 {
    .sect-4-container {
      .sec-4-row {
        .right-txt {
          .changing-txt {
            font-size: $smallHeading - 2 !important;
            text-align: justify;
            text-align-last: center;
            padding-top: 0;
          }
        }
      }
    }
  }
}

// section4 styling ends here
// section6 styling starts here

.our-work-section-6 {
  background-color: $white-bg;
  .sect-6-container {
    padding: 70px 0;
    color: $black;
    .descp {
      padding-right: 20px;
      padding-left: 20px;
      font-size: $smallHeading - 1 !important;
      font-weight: $regular;
      text-align: justify;
      word-spacing: -3px !important;
      line-height: normal;
      text-align-last: center;

      .span-txt {
        font-weight: $bold !important;
        color: $yellow;
      }

      .span-dash {
        font-weight: $bold !important;
        font-size: $largeHeading;
        color: $yellow;
      }
    }
  }
}

@media (min-width: 2001px) {
  .our-work-section-6 {
    .sect-6-container {
      .descp {
        padding-right: 20px;
        font-size: $mediumHeading + 1 !important;
      }
    }
  }
}

@media (max-width: 2000px) {
  .our-work-section-6 {
    .sect-6-container {
      .descp {
        padding-right: 450px;
        font-size: $mediumHeading + 1 !important;
      }
    }
  }
}

@media (max-width: 1700px) {
  .our-work-section-6 {
    .sect-6-container {
      .descp {
        padding-right: 20px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .our-work-section-6 {
    .sect-6-container {
      .descp {
        padding-right: 20px;
        font-size: $mediumHeading - 5 !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .our-work-section-6 {
    .sect-6-container {
      padding-bottom: 50px;
      .descp {
        padding-right: 20px;
        font-size: $mediumHeading - 3 !important;
        text-align: justify;
        text-justify: inter-word;
        word-spacing: -3px;

        .span-dash {
          font-size: $largeHeading;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .our-work-section-6 {
    .sect-6-container {
      padding-bottom: 50px;
      .descp {
        padding-right: 20px;
        font-size: $mediumHeading - 4 !important;
        text-align: justify;

        .span-dash {
          font-size: $mediumHeading;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .our-work-section-6 {
    .sect-6-container {
      padding: 50px 10px;
      .descp {
        padding-right: 20px;
        font-size: $smallHeading - 3 !important;
        text-align: justify;
        text-align-last: center;
        word-spacing: -2px;

        .span-dash {
          font-size: $mediumHeading;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .our-work-section-6 {
    .sect-6-container {
      padding: 50px 10px;
    }
  }
}
