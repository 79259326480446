.meet-the-team-main {
  box-sizing: border-box;
}

// section-1 styling starts here

.team-section-1 {
  background-color: $dark-grey;
  overflow-y: hidden;
  padding-bottom: 50px;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  text-align: center;
  .sect-1-container {
    max-width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .txt-1 {
      .white-txt {
        padding: 0;
        margin-bottom: 0;
        overflow-y: hidden;
        color: $white-text;
        font-size: $largeHeading-vw;
        font-weight: $bold;
        word-spacing: -20px;
      }
      .yellow-txt {
        margin-bottom: 0;
        overflow-y: hidden;
        color: $yellow;
        font-weight: $bold;
      }
    }
    .txt-3 {
      .hollow-txt {
        margin-bottom: 0;
        overflow-y: hidden;
        color: transparent;
        font-size: $largeHeading-vw - 0.5;
        font-weight: $bold;
        word-spacing: -17px;
        -webkit-text-stroke: 2px $white-text;
        -webkit-background-clip: text; //to send text to the background
        background-image: radial-gradient(
          closest-side,
          $yellow 100%,
          transparent 0
        ); //for the yellow ball in hollow text
        background-repeat: no-repeat;
        background-size: 400px 400px;
      }
    }

    // to add fade down effect to the scroller text

    @keyframes fadeDown {
      from {
        opacity: 0;
        transform: translateY(-100%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .scroller {
      overflow-y: hidden;
      padding-top: 50px;
      animation: fadeDown 1.5s ease-in-out;
      padding-bottom: 5px;
      text-align: center;
      .scroller-text {
        color: $white-text;
        font-size: $smallFont + 2;
        font-weight: $medium;
        margin-bottom: 5px;
      }
      .i {
        font-size: $xsmallHeading;
        font-weight: $thin;
        color: $white-text;
        transition: all 0.5s ease-in-out;
      }
      .i:hover {
        color: $yellow;
        transform: translateY(10px);
      }
    }
  }
}

@media (max-width: 1024px) {
  .team-section-1 {
    .sect-1-container {
      max-width: fit-content;
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw - 0.2;
        }
      }
    }
    .scroller {
      padding-top: 40px !important;
    }
  }
}

// to disable hollow text animation for screen smaller than 992px
@media (max-width: 991px) {
  .team-section-1 {
    .txt-3 {
      .hollow-txt {
        background-image: radial-gradient(
          closest-side,
          transparent 100%,
          transparent 0
        ) !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .team-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 2;
          word-spacing: 0;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2;
          word-spacing: 0;
          color: $white-text !important;
          -webkit-text-stroke: 0px $white-text !important;
          opacity: 1 !important;
          word-spacing: normal;
        }
      }
      .scroller {
        .scroller-text {
          font-size: $smallFont;
        }
        .i {
          font-size: $xsmallHeading - 2;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .team-section-1 {
    .sect-1-container {
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2.5;
          -webkit-text-stroke: 1px $white-text;
          letter-spacing: 2px;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .team-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 2.5;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2.5;
        }
      }
      .scroller {
        .scroller-text {
          font-size: $smallFont - 2;
        }
        .i {
          font-size: $xsmallHeading - 4;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .team-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .team-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-3 {
        .hollow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
    }
  }
}

// section-1 styling ends here
// section-2 styling starts here

.team-section-2 {
  background-color: $dark-grey;
  overflow-y: hidden;
  padding: 50px 0;

  .team-video-container {
    .video-row {
      // pointer-events: none;
      .video {
        // pointer-evenmts: none;
        width: 100%;
        height: 500px;
        border-radius: 30px;
      }
    }
  }
}

@media (max-width: 767px) {
  .team-section-2 {
    padding: 50px 10px;

    .team-video-container {
      .video-row {
        .video {
          height: 300px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .team-section-2 {
    .team-video-container {
      .video-row {
        .video {
          height: auto;
        }
      }
    }
  }
}

// section-2 styling ends here
// section-3 styling starts here

.team-section-3 {
  padding: 50px 0;
  text-align: center;
  background-color: $dark-grey;

  .team-title {
    padding-left: 5%;
    p {
      color: $yellow;
      text-align: left;
      font-size: $largeHeading;
      font-weight: $bold;
    }
  }
  .team-container {
    overflow-y: hidden;
    padding: 0 20px;
    .team-cards-row {
      padding-top: 50px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .team-member {
        background-color: $white-bg;
        margin-right: 20px;
        border-radius: 30px;
        width: 240px !important;
        min-height: 330px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: relative;

        .m-name {
          font-size: $largeFont1;
          font-weight: $semi-bold;
          color: $yellow;
          padding: 20px 20px 0px 20px;
          text-align: left;
          width: fit-content;
        }
        .m-designation {
          font-size: $mediumFont;
          font-weight: $regular;
          color: $light-grey;
          padding: 0 20px 20px 20px;
          text-align: left;
          width: fit-content;
        }
        .m-img {
          text-align: center;
          padding: 0;
          margin: 0;
          // width: 100%;

          position: absolute;
          bottom: 0 !important;
          img {
            width: 100%;
            height: auto;
          }
        }
      }

      .slick-slider {
        margin: 0 auto;
        width: 100%;

        .slick-list {
          overflow: visible;
        }

        .slick-slide {
          padding-left: 20px;
        }

        .slick-dots {
          position: absolute;
          bottom: -25px;
          left: 50%;
          transform: translateX(-50%);
          margin: 0;
          padding: 0;
          display: flex;
          justify-content: center;
          list-style: none;

          li {
            margin: 0 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 981px) {
  .team-section-3 {
    .team-title {
      p {
        font-size: $mediumHeading;
      }
    }
  }
}

@media (max-width: 767px) {
  .team-section-3 {
    padding: 50px 10px;
    .team-title {
      p {
        text-align: center;
      }
    }
  }
}

// section-3 styling ends here
// section-4 styling starts here

.team-section-4 {
  background-color: $dark-grey;
  padding: 50px 0;
  text-align: center;
  .about-team {
    h5 {
      padding-top: 30px;
      line-height: 2rem;
      text-align: justify;
      text-align-last: center;
      color: $white-text;
      font-weight: $regular;
    }
  }
}

@media (max-width: 767px) {
  .team-section-4 {
    padding: 50px 10px;
  }
}
