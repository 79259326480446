.services-main {
  box-sizing: border-box;
}

.services-section-1 {
  background-color: $dark-grey;
  overflow-y: hidden;
  padding-bottom: 50px;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  text-align: center;
  .sect-1-container {
    max-width: fit-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .txt-1 {
      .white-txt {
        padding: 0;
        margin-bottom: 0;
        overflow-y: hidden;
        color: $white-text;
        font-size: $largeHeading-vw - 1;
        font-weight: $bold;
        word-spacing: -11px;
      }
    }
    .txt-2 {
      .hollow-txt {
        margin-bottom: 0;
        overflow-y: hidden;
        color: transparent;
        font-size: $largeHeading-vw - 0.5;
        font-weight: $bold;
        word-spacing: -17px;
        -webkit-text-stroke: 2px $yellow;
        -webkit-background-clip: text; //to send text to the background
        background-image: radial-gradient(
          closest-side,
          $yellow 100%,
          transparent 0
        ); //for the yellow ball in hollow text
        background-repeat: no-repeat;
        background-size: 400px 400px;
      }
    }
    .services-desc {
      padding-top: 20px;
      .desc {
        color: $white-text;
        h6 {
        }
      }
    }

    // to add fade down effect to the scroller text

    @keyframes fadeDown {
      from {
        opacity: 0;
        transform: translateY(-100%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .scroller {
      overflow-y: hidden;
      padding-top: 60px;
      padding-bottom: 5px;
      text-align: center;
      animation: fadeDown 1.5s ease-in-out;

      .scroller-text {
        color: $white-text;
        font-size: $smallFont + 2;
        font-weight: $medium;
        margin-bottom: 5px;
      }
      .i {
        font-size: $xsmallHeading;
        font-weight: $thin;
        color: $white-text;
        transition: all 0.5s ease-in-out;
      }
      .i:hover {
        color: $yellow;
        transform: translateY(10px);
      }
    }
  }
}

@media (max-width: 1024px) {
  .services-section-1 {
    .sect-1-container {
      max-width: fit-content;
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 1;
        }
      }
      .txt-2 {
        .hollow-txt {
          font-size: $largeHeading-vw - 0.2;
        }
      }
    }
    .scroller {
      padding-top: 40px !important;
    }
  }
}

// to disable hollow text animation for screen smaller than 992px
@media (max-width: 991px) {
  .services-section-1 {
    .txt-2 {
      .hollow-txt {
        background-image: radial-gradient(
          closest-side,
          transparent 100%,
          transparent 0
        ) !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .services-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 2;
          word-spacing: 0;
        }
      }
      .txt-2 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2;
          word-spacing: 0;
          color: $yellow !important;
          -webkit-text-stroke: 0px $yellow !important;
          opacity: 1 !important;
        }
      }
      .scroller {
        .scroller-text {
          font-size: $smallFont;
        }
        .i {
          font-size: $xsmallHeading - 2;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .services-section-1 {
    .sect-1-container {
      .txt-2 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2.5;
          letter-spacing: 2px;
          -webkit-text-stroke: 1px $yellow;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .services-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 2.5;
        }
      }
      .txt-2 {
        .hollow-txt {
          font-size: $largeHeading-vw + 2.5;
        }
      }
      .scroller {
        .scroller-text {
          font-size: $smallFont - 2;
        }
        .i {
          font-size: $xsmallHeading - 4;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .services-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-2 {
        .hollow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .services-section-1 {
    .sect-1-container {
      .txt-1 {
        .white-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
      .txt-2 {
        .hollow-txt {
          font-size: $largeHeading-vw + 3;
        }
      }
    }
  }
}

// section1 styling ends here
// section2 styling starts here

.services-section-2 {
  .sect-2-container {
    .panel-1 {
      .panel-1-container {
        padding: 50px 20px;
        .services-desc {
          color: $white-text;
          text-align: justify;
          text-align-last: center;
          .txt {
            font-weight: $regular !important;
          }
        }
      }
    }
    .panel-2 {
      .panel-2-container {
        .panel-2-row {
          padding: 50px 10px 20px 10px;
          .service {
            padding-bottom: 30px;
            .srvc-img {
              img {
                width: 100%;
                height: auto;
                object-fit: cover !important;
              }
            }
            .srvc-title {
              padding: 30px 0 0 0;
              p {
                color: $yellow;
                font-weight: $semi-bold;
                font-size: $mediumHeading;
              }
            }
            .srvc-desc {
              text-align: justify;
              p {
                color: $black;
                font-weight: $thin !important;
                font-size: $regularFont;

                .bold {
                  color: $yellow;
                  font-weight: $heavy-bold-2;
                }
                .light {
                  color: $yellow;
                  font-weight: $bold;
                }
                .italic {
                  color: $yellow;
                  font-weight: $bold;
                  font-style: italic;
                }
                .regular {
                  color: $yellow;
                  font-weight: $semi-bold;
                }
              }
              .myBtn {
                color: $light-grey !important;
                background: transparent;
                border: none;
                font-size: $regularFont;
                font-weight: $semi-bold;

                .i {
                  color: $yellow;
                  font-weight: $semi-bold;
                  font-size: $mediumFont;
                }
              }
              .myBtn:active {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
